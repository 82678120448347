import React, { useState } from "react";
import moment from "moment";

const TrialBooking = ({ handleChange }) => {
    const [submitted, setSubmitted] = useState(0);
    const [error, setError] = useState("");
    const [trialDate, setTrialDate] = useState(moment().format("YYYY-MM-DD"));
    const [trialTime, setTrialTime] = useState("");
    const [trialType, setTrialType] = useState("");
    const [trialLink, setTrialLink] = useState("");
    const [selectedSession, setSelectedSession] = useState("");

    const sessionList = {
        Monday: [
            {
                name: "Yoga",
                timing: "6:30am - 7:30am",
                startTime: 630,
                trainer: "Shradda",
            },
            {
                name: "Yoga",
                timing: "8:00am - 9:00am",
                startTime: 800,
                trainer: "Shradda",
            },
            {
                name: "Yoga",
                timing: "12:30pm - 1:30pm",
                startTime: 1230,
                trainer: "Vinutha",
            },
            {
                name: "Zumba",
                timing: "5:00pm - 6:00pm",
                startTime: 1700,
                trainer: "Angela",
            },
            {
                name: "Yoga",
                timing: "5:30pm - 6:30pm",
                startTime: 1730,
                trainer: "Shradda",
            },
            {
                name: "Pop Pilates",
                timing: "7:00pm - 8:00pm",
                startTime: 1900,
                trainer: "Rachna",
            },
            {
                name: "Yoga",
                timing: "7:00pm - 8:00pm",
                startTime: 1900,
                trainer: "Vinutha",
            },
        ],
        Tuesday: [
            {
                name: "Yoga",
                timing: "6:30am - 7:30am",
                startTime: 630,
                trainer: "Shradda",
            },
            {
                name: "Yoga",
                timing: "8:00am - 9:00am",
                startTime: 800,
                trainer: "Shradda",
            },
            {
                name: "Yoga",
                timing: "12:30pm - 1:30pm",
                startTime: 1230,
                trainer: "Vinutha",
            },
            {
                name: "Zumba",
                timing: "5:00pm - 6:00pm",
                startTime: 1700,
                trainer: "Angela",
            },
            {
                name: "Yoga",
                timing: "5:30pm - 6:30pm",
                startTime: 1730,
                trainer: "Shradda",
            },
            {
                name: "Pop Pilates",
                timing: "7:00pm - 8:00pm",
                startTime: 1900,
                trainer: "Rachna",
            },
            {
                name: "Yoga",
                timing: "7:00pm - 8:00pm",
                startTime: 1900,
                trainer: "Vinutha",
            },
        ],
        Wednesday: [
            {
                name: "Yoga",
                timing: "6:30am - 7:30am",
                startTime: 630,
                trainer: "Shradda",
            },
            {
                name: "Yoga",
                timing: "8:00am - 9:00am",
                startTime: 800,
                trainer: "Shradda",
            },
            {
                name: "Yoga",
                timing: "12:30pm - 1:30pm",
                startTime: 1230,
                trainer: "Vinutha",
            },
            {
                name: "Zumba",
                timing: "5:00pm - 6:00pm",
                startTime: 1700,
                trainer: "Angela",
            },
            {
                name: "Yoga",
                timing: "5:30pm - 6:30pm",
                startTime: 1730,
                trainer: "Shradda",
            },
            {
                name: "Pop Pilates",
                timing: "7:00pm - 8:00pm",
                startTime: 1900,
                trainer: "Rachna",
            },
            {
                name: "Yoga",
                timing: "7:00pm - 8:00pm",
                startTime: 1900,
                trainer: "Vinutha",
            },
        ],
        Thursday: [
            {
                name: "Yoga",
                timing: "6:30am - 7:30am",
                startTime: 630,
                trainer: "Shradda",
            },
            {
                name: "Yoga",
                timing: "8:00am - 9:00am",
                startTime: 800,
                trainer: "Shradda",
            },
            {
                name: "Yoga",
                timing: "12:30pm - 1:30pm",
                startTime: 1230,
                trainer: "Vinutha",
            },
            {
                name: "Zumba",
                timing: "5:00pm - 6:00pm",
                startTime: 1700,
                trainer: "Angela",
            },
            {
                name: "Yoga",
                timing: "5:30pm - 6:30pm",
                startTime: 1730,
                trainer: "Shradda",
            },
            {
                name: "Pop Pilates",
                timing: "7:00pm - 8:00pm",
                startTime: 1900,
                trainer: "Rachna",
            },
            {
                name: "Yoga",
                timing: "7:00pm - 8:00pm",
                startTime: 1900,
                trainer: "Vinutha",
            },
        ],
        Friday: [
            {
                name: "Yoga",
                timing: "6:30am - 7:30am",
                startTime: 630,
                trainer: "Shradda",
            },
            {
                name: "Yoga",
                timing: "8:00am - 9:00am",
                startTime: 800,
                trainer: "Shradda",
            },
            {
                name: "Yoga",
                timing: "12:30pm - 1:30pm",
                startTime: 1230,
                trainer: "Vinutha",
            },
            {
                name: "Zumba",
                timing: "5:00pm - 6:00pm",
                startTime: 1700,
                trainer: "Angela",
            },
            {
                name: "Yoga",
                timing: "5:30pm - 6:30pm",
                startTime: 1730,
                trainer: "Shradda",
            },
            {
                name: "Pop Pilates",
                timing: "7:00pm - 8:00pm",
                startTime: 1900,
                trainer: "Rachna",
            },
            {
                name: "Yoga",
                timing: "7:00pm - 8:00pm",
                startTime: 1900,
                trainer: "Vinutha",
            },
        ],
        Saturday: [
            {
                name: "Yoga",
                timing: "8:00am - 9:00am",
                startTime: 800,
                trainer: "Shradda",
            },
            {
                name: "Yoga",
                timing: "9:00am - 10:00am",
                startTime: 900,
                trainer: "Shradda",
            },
            {
                name: "Yoga",
                timing: "12:30pm - 1:30pm",
                startTime: 1230,
                trainer: "Vinutha",
            },
            {
                name: "Yoga",
                timing: "7:00pm - 8:00pm",
                startTime: 1900,
                trainer: "Vinutha",
            },
        ],
        Sunday: [],
    };

    const handleNextClick = () => {
        if (selectedSession === "") {
            setError("Please select a slot before proceeding");
        } else {
            setSubmitted(1);
            handleChange("trial", {
                data: 1,
                trialDate: `${trialDate.trim()} ${trialTime.trim()}`,
                trialType,
                trialLink,
            });
        }
    };

    const handleDateChange = (newDate) => {
        setTrialDate(newDate);
        setSelectedSession("");
    };

    const handleSkip = () => {
        setSubmitted(1);
        handleChange("trial", { data: 0 });
    };

    const selectSlot = (val) => {
        setSelectedSession(`${val.name}_${val.timing}_${val.trainer}`);
        setTrialTime(val.timing.split("-")[0]);
        setTrialType(val.name);
        setTrialLink(
            val.name === "Yoga"
                ? "https://zoom.us/j/9810747476?pwd=RVFrSkY5RXRCQ09wWkFWeEpzeTZIdz09"
                : val.name === "Zumba"
                ? "https://zoom.us/j/5193961553?pwd=cUhtc2ZmMnJsRUNqemdXVUZwUE8vdz09"
                : val.name === "Pop Pilates"
                ? "https://zoom.us/j/94844963009"
                : val.name === "Pre-natal Yoga"
                ? "https://zoom.us/j/9810747476?pwd=RVFrSkY5RXRCQ09wWkFWeEpzeTZIdz09"
                : val.name === "Post-natal Yoga"
                ? "https://zoom.us/j/3663077574?pwd=WjNKRERZOThOUm1NVEprRlhqU0QwZz09"
                : ""
        );
    };

    const renderTimeSlots = (sessions) => {
        return sessions.map((val) => {
            return (
                <div
                    key={Math.random()}
                    className={
                        `${val.name}_${val.timing}_${val.trainer}` ===
                        selectedSession
                            ? "answer-multi-selected col-12"
                            : "answer-multi col-12"
                    }
                    onClick={() => selectSlot(val)}
                >
                    {val.timing}
                </div>
            );
        });
    };

    const renderTrialSlots = () => {
        const dayOfTheWeek = moment(trialDate).format("dddd");
        const sessions = sessionList[dayOfTheWeek];
        const currentTime = parseInt(moment().format("HHmm"));
        const currentDate = moment().format("YYYY-MM-DD");
        let sessionsToggle = sessions.length > 0 ? true : false;

        const dayDiff = moment().diff(moment(trialDate), "days");

        const yogaSessions = [];
        const postNatalYogaSessions = [];
        const preNatalYogaSessions = [];
        const zumbaSessions = [];
        const popPilatesSessions = [];

        if (dayDiff <= 0) {
            sessions.forEach((val) => {
                if (trialDate === currentDate) {
                    if (val.startTime > currentTime) {
                        if (val.name === "Yoga") {
                            yogaSessions.push(val);
                        } else if (val.name === "Pre-natal Yoga") {
                            preNatalYogaSessions.push(val);
                        } else if (val.name === "Post-natal Yoga") {
                            postNatalYogaSessions.push(val);
                        } else if (val.name === "Zumba") {
                            zumbaSessions.push(val);
                        } else if (val.name === "Pop Pilates") {
                            popPilatesSessions.push(val);
                        }
                    }
                } else {
                    if (val.name === "Yoga") {
                        yogaSessions.push(val);
                    } else if (val.name === "Pre-natal Yoga") {
                        preNatalYogaSessions.push(val);
                    } else if (val.name === "Post-natal Yoga") {
                        postNatalYogaSessions.push(val);
                    } else if (val.name === "Zumba") {
                        zumbaSessions.push(val);
                    } else if (val.name === "Pop Pilates") {
                        popPilatesSessions.push(val);
                    }
                }
            });
        } else {
            sessionsToggle = false;
        }

        return (
            <div className="slots-container">
                {sessionsToggle === false ? (
                    <h3 className="session-header-none">
                        No sessions available on this date
                    </h3>
                ) : null}
                {yogaSessions.length > 0 ? (
                    <div className="session">
                        <h3 className="session-header">Yoga 🧘🏾‍♀️</h3>
                        <hr />
                        {renderTimeSlots(yogaSessions)}
                    </div>
                ) : null}

                {zumbaSessions.length > 0 ? (
                    <div className="session">
                        <h3 className="session-header">Zumba 💃🏼</h3>
                        <hr />
                        {renderTimeSlots(zumbaSessions)}
                    </div>
                ) : null}

                {popPilatesSessions.length > 0 ? (
                    <div className="session">
                        <h3 className="session-header">Pop Pilates 💃🏼</h3>
                        <hr />
                        {renderTimeSlots(popPilatesSessions)}
                    </div>
                ) : null}

                {preNatalYogaSessions.length > 0 ? (
                    <div className="session">
                        <h3 className="session-header">Pre-natal Yoga 🧘🏾‍♀️</h3>
                        <hr />
                        {renderTimeSlots(preNatalYogaSessions)}
                    </div>
                ) : null}

                {postNatalYogaSessions.length > 0 ? (
                    <div className="session">
                        <h3 className="session-header">Post-natal Yoga 🧘🏾‍♀️</h3>
                        <hr />
                        {renderTimeSlots(postNatalYogaSessions)}
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <div className="uvi-landing-main">
            <h2 className="quiz-home-hero-title">
                That's it! You have unlocked your Free Trial 🎊
            </h2>

            <h4 className="quiz-home-hero-subtitle">
                Book a slot below and join a free PCOS fitness session
            </h4>

            <form autoComplete="off" className="form-horizontal">
                <input
                    autoComplete="false"
                    name="hidden"
                    type="text"
                    style={{ display: "none" }}
                />

                <div className="form-group row mb-3">
                    <div className="col-12">
                        <input
                            autoComplete="off"
                            type="date"
                            className="form-control"
                            name="mobileNumber"
                            placeholder="Your mobile number with country code"
                            onChange={(e) => {
                                handleDateChange(e.target.value);
                            }}
                            value={trialDate}
                            maxLength="10"
                            pattern="\d{10}"
                        />
                    </div>
                </div>
            </form>

            <h4 className="quiz-home-hero-subtitle">
                <em>
                    All slot timings are in IST - Indian Standard Time (GMT +
                    5:30)
                </em>
            </h4>

            <div className="row">
                <div className="col-12">{renderTrialSlots()}</div>
            </div>

            {error ? <h4 className="mcq-error-alert">{error}</h4> : null}

            <div>
                <div className="row">
                    <div className="col-6">
                        <button
                            className="btn btn-link quiz-next-skip"
                            onClick={handleSkip}
                            disabled={submitted === 0 ? false : true}
                        >
                            Skip
                        </button>
                    </div>
                    <div className="col-6">
                        <button
                            className="btn btn-primary quiz-next"
                            onClick={handleNextClick}
                            disabled={submitted === 0 ? false : true}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrialBooking;
