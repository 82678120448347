import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import request from 'request';

import ThyroidReport from './report';

const ThyroidFinalReport = () => {
    const [dataReceived, setDataReceived] = useState(0);
    const { id } = useParams();

    console.log(id);

    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [concern, setConcern] = useState('');
    const [diagnosed, setDiagnosed] = useState('');
    const [ciScore, setCIScore] = useState('');
    const [gdScore, setGDScore] = useState('');
    const [msScore, setMSScore] = useState('');
    const [ndScore, setNDScore] = useState('');
    const [geScore, setGEScore] = useState('');
    const [mainSymptoms, setMainSymptoms] = useState('');

    useEffect(() => {
        var options = {
            method: 'GET',
            headers: {'Authorization': 'Bearer patEDoXvb5BCD036v.26a7bd6bb84733958c16be47db2551fa2f7daebc1720d6f3665d1c4bc3f99d45'},
            url: `https://api.airtable.com/v0/appwFxqOd7nt23PIX/Thyroid?filterByFormula={ID}="${id}"`,
        };

        let dataMatch = false;

        if (dataReceived === 0) {
            request(options, (error, response, body) => {
                if (error) {
                    setDataReceived(2);
                } else if (body) {
                    try {
                        const apiData = JSON.parse(body);
                        if (apiData.records.length > 0) {
                            apiData.records.forEach((val) => {
                                console.log(val);
                                if (val.fields.ID === id) {
                                    dataMatch = true;
                                    setDataReceived(1);

                                    setName(val.fields['Name']);
                                    setAge(val.fields['Age']);
                                    setHeight(val.fields['Height']);
                                    setWeight(val.fields['Weight']);
                                    setConcern(val.fields['Concern']);
                                    setDiagnosed(val.fields['Diagnosed']);
                                    setCIScore(val.fields['CI Score']);
                                    setGDScore(val.fields['GD Score']);
                                    setMSScore(val.fields['MS Score']);
                                    setNDScore(val.fields['ND Score']);
                                    setGEScore(val.fields['GE Score']);
                                    setMainSymptoms(val.fields['Main Symptoms'])
                                }
                            });

                            if (!dataMatch) {
                                setDataReceived(2);
                            }
                        } else {
                            setDataReceived(2);
                        }
                    } catch (error) {
                        setDataReceived(2);
                    }
                }
            });
        }
    }, [dataReceived, id]);

    const loadingReport = () => {
        return (
            <div style={{ marginTop: '30vh' }}>
                <h2 className="quiz-report-hero-1">Loading report ...</h2>
            </div>
        );
    };

    const returnNoReport = () => {
        return (
            <div style={{ marginTop: '20vh' }}>
                <div className="col-12">
                    <h2 className="quiz-report-hero-1">No Report Found</h2>

                    <a
                        className="btn btn-primary talk-to-health-coach"
                        href="https://uvihealth.in"
                        style={{
                            marginTop: '3em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        Visit Uvi Health
                    </a>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="container2" id="container">
                <div className="col-12">
                    <img src="/assets/images/uvi-logo.svg" className="uvi-logo" alt="uvi-logo" />
                </div>

                {dataReceived === 0 ? (
                    loadingReport()
                ) : dataReceived === 2 ? (
                    returnNoReport()
                ) : (
                    <ThyroidReport
                        name={name}
                        age={age}
                        height={height}
                        weight={weight}
                        concern={concern}
                        diagnosed={diagnosed}
                        ciScore={ciScore}
                        gdScore={gdScore}
                        msScore={msScore}
                        ndScore={ndScore}
                        geScore={geScore}
                        mainSymptoms={mainSymptoms}
                    />
                )}
            </div>
        </div>
    );
};

export default ThyroidFinalReport;
