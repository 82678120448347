/* eslint-disable no-fallthrough */
/* eslint-disable no-duplicate-case */
/* eslint-disable default-case */
import axios from "axios";
import Cookies from "js-cookie";
import { apiSuccess, apiError, API_REQUEST } from "../../../Actions/api";
import { forceLogout, getLoggedOut, LOGIN, LOGOUT, setAuthentication } from "../../../Actions/login";

export const apiMiddleware = ({ dispatch }) => next => async action => {
    next(action);
    if (action.type.includes(API_REQUEST)) {
        let resp;
        try {
            switch (action.meta.feature) {
                case LOGIN:
                case LOGOUT:
                    if (action.meta.method === "get") {
                        if (action.meta.feature === LOGOUT) {
                            await axios.get(action.meta.url, { withCredentials: true, credentials: "include" });
                            dispatch(setAuthentication(false));
                            break;
                        }
                        resp = await axios.get(action.meta.url, {
                            withCredentials: true,
                            credentials: "include",
                            params: { queryVal: action.payload }
                        });
                        dispatch(apiSuccess(resp.data.responseData, action.meta.feature));
                        break;
                    }
                    if (action.meta.method === "post") {
                        if (action.meta.feature === LOGIN) {
                            await axios.post(action.meta.url, action.payload, {
                                withCredentials: true,
                                credentials: "include"
                            });
                            dispatch(setAuthentication(Cookies.get("isAuthenticated")));
                            break;
                        } else {
                            axios.post(action.meta.url, action.payload, {
                                withCredentials: true,
                                credentials: "include"
                            });
                        }
                    } else if (action.meta.method === "put") {
                        resp = await axios.put(action.meta.url, action.payload, {
                            withCredentials: true,
                            credentials: "include"
                        });
                    } else if (action.meta.method === "delete") {
                        action.meta.config.data = action.payload;
                        resp = await axios.delete(action.meta.url, { withCredentials: true, credentials: "include" });
                    }
                    dispatch(apiSuccess(resp.data.responseData, action.meta.feature));
                    break;
            }
        } catch (err) {
            if (err.response.status === 401) {
                Cookies.remove("isAuthenitcated", { path: "/", domain: "localhost" });
                dispatch(forceLogout());
            }
            dispatch(apiError([], action.meta.feature));
        }
    }
};
