import React, { useState } from 'react';

const QuizEatingDisorders = ({ handleChange }) => {
    const [value, setValue] = useState('');
    const [error, setError] = useState(0);

    const setAnswer = (answer) => {
        setValue(answer === 'Yes' ? 1 : answer === 'No' ? 0 : '');
    };

    const handleNextClick = () => {
        if (value === '') {
            setError(1);
        } else {
            handleChange('eating-disorders', { value, totalValue: 1 });
        }
    };
    const handleBackClick = () => {

        handleChange('eating-disorders-back');

    };


    return (
        <div className="uvi-landing-main extra-padding">
            <div className="progress progress-md">
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: '84%', backgroundColor: '#DC4A9A' }}
                    aria-valuenow="84"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
            <h2 className="quiz-home-hero-title">
                Do you have any eating disorders, or did you have one in past? (for example - binge eating, anorexia,
                etc.)
            </h2>

            <div className="row answer-section">
                <div className="col-12">
                    <div
                        className={value === 1 ? 'quiz-options-selected' : 'quiz-options'}
                        onClick={() => setAnswer('Yes')}
                    >
                        <i className={
                            value === 1 ? 'fa-solid fa-circle-dot' : 'fa-regular fa-circle'
                        }>
                        </i>&nbsp;&nbsp;
                        Yes

                    </div>
                </div>
                <div className="col-12">
                    <div
                        className={value === 0 ? 'quiz-options-selected' : 'quiz-options'}
                        onClick={() => setAnswer('No')}
                    >
                        <i className={
                            value === 0 ? 'fa-solid fa-circle-dot' : 'fa-regular fa-circle'
                        }>
                        </i>&nbsp;&nbsp;
                        No
                    </div>
                </div>
            </div>

            {error === 1 ? <h4 className="mcq-error-alert">Please select one of the options above</h4> : null}

            <div>
                <div className="row">
                    <div className="col-12">
                        <button className="btn btn-primary quiz-next" onClick={handleNextClick}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <div>
                                    Next
                                </div>
                                <div>
                                    <img src="/assets/images/arrow-right.svg" className="arrow-right" alt="arrow-right" />
                                </div>
                            </div>
                        </button>
                        <button
                            className="btn btn-primary quiz-back"

                            onClick={handleBackClick}>
                            Back
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuizEatingDisorders;
