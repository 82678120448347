import { createStore, applyMiddleware, compose, combineReducers } from "redux";

import { homeReducer } from "../Reducers/home";

import { apiMiddleware } from "../middleware/core/api";
import { loginMiddleWare } from "../middleware/feature/login";

const coreMiddleware = [apiMiddleware];
const featureMiddleware = [loginMiddleWare];

const enhance = compose(applyMiddleware(...featureMiddleware, ...coreMiddleware));
const rootReducer = combineReducers({
    homeReducer
});
const store = createStore(rootReducer, enhance);

export default store;
