import React, { useState, useEffect } from 'react';

const ThyroidQuizQuestion = ({ name, question, handleChange }) => {
    const [username] = useState(name);
    const [topic, setTopic] = useState(question.data[0].target);
    const [backLocation, setBackLocation] = useState(question.backward);
    const [forwardLocation, setForwardLocation] = useState(question.forward);
    const [questionData, setQuestionData] = useState({});
    const [data, setData] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isDisabled, setIsDisabled] =  useState(true);

    const [countryCode, setCountryCode] = useState('91');
    const countryCodeList = {
        Afghanistan: '93',
        Albania: '355',
        Algeria: '213',
        Andorra: '376',
        Angola: '244',
        Antarctica: '672',
        Argentina: '54',
        Armenia: '374',
        Aruba: '297',
        Australia: '61',
        Austria: '43',
        Azerbaijan: '994',
        Bahrain: '973',
        Bangladesh: '880',
        Belarus: '375',
        Belgium: '32',
        Belize: '501',
        Benin: '229',
        Bhutan: '975',
        Bolivia: '591',
        'Bosnia and Herzegovina': '387',
        Botswana: '267',
        Brazil: '55',
        'British Indian Ocean Territory': '246',
        Brunei: '673',
        Bulgaria: '359',
        'Burkina Faso': '226',
        Burundi: '257',
        Cambodia: '855',
        Cameroon: '237',
        Canada: '1',
        'Cape Verde': '238',
        'Central African Republic': '236',
        Chad: '235',
        Chile: '56',
        China: '86',
        'Christmas Island': '61',
        'Cocos Islands': '61',
        Colombia: '57',
        Comoros: '269',
        'Cook Islands': '682',
        'Costa Rica': '506',
        Croatia: '385',
        Cuba: '53',
        Curacao: '599',
        Cyprus: '357',
        'Czech Republic': '420',
        'Democratic Republic of the Congo': '243',
        Denmark: '45',
        Djibouti: '253',
        'East Timor': '670',
        Ecuador: '593',
        Egypt: '20',
        'El Salvador': '503',
        'Equatorial Guinea': '240',
        Eritrea: '291',
        Estonia: '372',
        Ethiopia: '251',
        'Falkland Islands': '500',
        'Faroe Islands': '298',
        Fiji: '679',
        Finland: '358',
        France: '33',
        'French Polynesia': '689',
        Gabon: '241',
        Gambia: '220',
        Georgia: '995',
        Germany: '49',
        Ghana: '233',
        Gibraltar: '350',
        Greece: '30',
        Greenland: '299',
        Guatemala: '502',
        Guinea: '224',
        'Guinea-Bissau': '245',
        Guyana: '592',
        Haiti: '509',
        Honduras: '504',
        'Hong Kong': '852',
        Hungary: '36',
        Iceland: '354',
        India: '91',
        Indonesia: '62',
        Iran: '98',
        Iraq: '964',
        Ireland: '353',
        Israel: '972',
        Italy: '39',
        'Ivory Coast': '225',
        Japan: '81',
        Jordan: '962',
        Kazakhstan: '7',
        Kenya: '254',
        Kiribati: '686',
        Kosovo: '383',
        Kuwait: '965',
        Kyrgyzstan: '996',
        Laos: '856',
        Latvia: '371',
        Lebanon: '961',
        Lesotho: '266',
        Liberia: '231',
        Libya: '218',
        Liechtenstein: '423',
        Lithuania: '370',
        Luxembourg: '352',
        Macau: '853',
        Macedonia: '389',
        Madagascar: '261',
        Malawi: '265',
        Malaysia: '60',
        Maldives: '960',
        Mali: '223',
        Malta: '356',
        'Marshall Islands': '692',
        Mauritania: '222',
        Mauritius: '230',
        Mayotte: '262',
        Mexico: '52',
        Micronesia: '691',
        Moldova: '373',
        Monaco: '377',
        Mongolia: '976',
        Montenegro: '382',
        Morocco: '212',
        Mozambique: '258',
        Myanmar: '95',
        Namibia: '264',
        Nauru: '674',
        Nepal: '977',
        Netherlands: '31',
        'Netherlands Antilles': '599',
        'New Caledonia': '687',
        'New Zealand': '64',
        Nicaragua: '505',
        Niger: '227',
        Nigeria: '234',
        Niue: '683',
        'North Korea': '850',
        Norway: '47',
        Oman: '968',
        Pakistan: '92',
        Palau: '680',
        Palestine: '970',
        Panama: '507',
        'Papua New Guinea': '675',
        Paraguay: '595',
        Peru: '51',
        Philippines: '63',
        Pitcairn: '64',
        Poland: '48',
        Portugal: '351',
        Qatar: '974',
        'Republic of the Congo': '242',
        Reunion: '262',
        Romania: '40',
        Russia: '7',
        Rwanda: '250',
        'Saint Barthelemy': '590',
        'Saint Helena': '290',
        'Saint Martin': '590',
        'Saint Pierre and Miquelon': '508',
        Samoa: '685',
        'San Marino': '378',
        'Sao Tome and Principe': '239',
        'Saudi Arabia': '966',
        Senegal: '221',
        Serbia: '381',
        Seychelles: '248',
        'Sierra Leone': '232',
        Singapore: '65',
        Slovakia: '421',
        Slovenia: '386',
        'Solomon Islands': '677',
        Somalia: '252',
        'South Africa': '27',
        'South Korea': '82',
        'South Sudan': '211',
        Spain: '34',
        'Sri Lanka': '94',
        Sudan: '249',
        Suriname: '597',
        'Svalbard and Jan Mayen': '47',
        Swaziland: '268',
        Sweden: '46',
        Switzerland: '41',
        Syria: '963',
        Taiwan: '886',
        Tajikistan: '992',
        Tanzania: '255',
        Thailand: '66',
        Togo: '228',
        Tokelau: '690',
        Tonga: '676',
        Tunisia: '216',
        Turkey: '90',
        Turkmenistan: '993',
        Tuvalu: '688',
        Uganda: '256',
        Ukraine: '380',
        'United Arab Emirates': '971',
        'United Kingdom': '44',
        'United States': '1',
        Uruguay: '598',
        Uzbekistan: '998',
        Vanuatu: '678',
        Vatican: '379',
        Venezuela: '58',
        Vietnam: '84',
        'Wallis and Futuna': '681',
        'Western Sahara': '212',
        Yemen: '967',
        Zambia: '260',
        Zimbabwe: '263',
    };

    useEffect(() => {
        setBackLocation(question.backward);
        setForwardLocation(question.forward);
    }, [question]);

    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }
    useEffect(() => {
        switch (question.data[0].target) {
            case ('name'):
                setIsDisabled(data.trim().length < 1 )
                break;
            case ('age'):
                setIsDisabled(!(data >= 1 &&  data <= 120) )
                break;    
            case ('height'):
                setIsDisabled(!(data >= 100 &&  data <= 220) )
                break;    
            case ('weight'):
                setIsDisabled(!(data >= 30 &&  data <= 150) )
                break;
            case ('mobileNumber'):
                setIsDisabled(data.length < 5 ||  data.length > 15 || data.trim() == "" || !isNumeric(data))
                break;
            default:
                setIsDisabled(!(data || selectedOptions.length > 0))
        }
    }, [data]);

    const handleSubmit = (direction) => {
        let finalData = null;
        switch (question.data[0].target) {
            case ('mobileNumber'):
                finalData = `${countryCode}${data}`;
                break;
            case ('main-symptoms'):
                finalData = selectedOptions.toString();
                break;
            default:
                finalData = data
        }
        const submitData = {
            topic: question.data[0].target,
            data: finalData,
            next: direction === 'back' ? backLocation : forwardLocation,
            questionData,
        };

        setTopic('');
        setData('');
        setBackLocation('');
        setForwardLocation('');
        setQuestionData({});
        setSelectedOptions([]);

        handleChange('question', { ...submitData });
    };

    const makeSelection = (d) => {
        if (!selectedOptions.includes(d.value)) {
            let currentOptions = JSON.parse(JSON.stringify(selectedOptions));
            if (d.type === 'single-choice-item') {
                currentOptions = [d.value];
            } else {
                currentOptions.push(d.value);
            }

            if ('score' in d) {
                setTopic(d.target);
                if (d.type === 'single-choice-item') {
                    setData(d.score);
                } else if (d.type === 'multi-choice-item') {
                    setData(data ? data + d.score : d.score);
                }
            } else {
                setTopic(d.target);
                setData(d.value);
            }

            if (d.forward) {
                setForwardLocation(d.forward);
            }

            setSelectedOptions(currentOptions);
        } else {
            const currentOptions = selectedOptions.filter((item) => item !== d.value);

            if ('score' in d) {
                setTopic(d.target);
                if (d.type === 'single-choice-item') {
                    setData('');
                } else if (d.type === 'multi-choice-item') {
                    setData(data ? data - d.score : 0);
                }
            } else {
                setTopic(d.target);
                currentOptions.length === 0 && setData('');
            }

            if (d.forward) {
                currentOptions.length === 0 && setForwardLocation('');
            }

            setSelectedOptions(currentOptions);
        }
    };

    const renderProgress = () => {
        return (
            <div>
                <h3 className="quiz-home-hero-section">{question.section}</h3>
                <div className="progress progress-md">
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${question.progress}%`, backgroundColor: '#DC4A9A' }}
                        aria-valuenow={question.progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >

                    </div>
                </div>
            </div>
        );
    };

    const renderIcon = (data, type) => {
        if (selectedOptions.includes(data) && type === 'single-choice-item') {
            return <i className="fa-solid fa-circle-dot"></i>;
        } else if (selectedOptions.includes(data) && type === 'multi-choice-item') {
            return <i className="fa-solid fa-square-check"></i>;
        } else if (!selectedOptions.includes(data) && type === 'single-choice-item') {
            return <i className="fa-regular fa-circle"></i>;
        } else if (!selectedOptions.includes(data) && type === 'multi-choice-item') {
            return <i className="fa-regular fa-square"></i>;
        } else {
            return null;
        }
    };

    const renderPills = (d) => {
        return d.map((item) => {
            return (
                <div
                    key={item.value}
                    className={selectedOptions.includes(item.value) ? 'quiz-options-selected' : 'quiz-options'}
                    onClick={() => makeSelection(item)}
                >
                    {renderIcon(item.value, item.type)}&nbsp;&nbsp;
                    {item.value}
                </div>
            );
        });
    };

    const renderCountryCodes = () => {
        return Object.keys(countryCodeList).map((val) => {
            return (
                <option key={val} value={countryCodeList[val]} selected={countryCode === countryCodeList[val]}>
                    {val}
                </option>
            );
        });
    };

    const renderPhoneNumber = () => {
        return (
            <div className="row">
                <div className="col-4">
                    <select
                        className="form-control"
                        style={{ height: 'calc(2.25rem + 20px)' }}
                        onChange={(e) => setCountryCode(e.target.value)}
                    >
                        {renderCountryCodes()}
                    </select>
                </div>

                <div className="col-8">
                    <input
                        type="text"
                        placeholder="Enter your mobile number"
                        style={{ height: 'calc(2.25rem + 20px)' }}
                        className="form-control"
                        value={data}
                        onChange={(e) => setData(e.target.value)}
                    />
                </div>
            </div>
        );
    };

    const renderOptions = () => {
        if (question.type === 'input-box') {
            return (
                <input
                    type="text"
                    placeholder={question.data[0].placeholder}
                    style={{ height: 'calc(2.25rem + 20px)' }}
                    className="form-control"
                    value={data}
                    onChange={(e) => setData(e.target.value)}
                />
            );
        } else if (question.type === 'phone-number') {
            return renderPhoneNumber();
        } else if (question.type === 'single-choice' || 'multi-choice') {
            return renderPills(question.data);
        }
    };

    const renderBack = () => {
        return (
            <div className="col-8 .offset-md-2">
                {backLocation && (
                    <div
                        style={{
                            color: '#275070',
                            fontWeight: 500,
                            width: '62px',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                        }}

                        onClick={() => handleSubmit('back')}
                    >
                        Go Back
                    </div>
                )}
            </div>
        );
    };

    const renderForward = () => {
        return (
            <div className="col-8 .offset-md-2">
                {forwardLocation ? (
                    <button
                        className="btn btn-primary quiz-next btn-block"
                        disabled={!isDisabled ? null : '1'}
                        onClick={() => handleSubmit('forward')}
                    >
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <div>
                                Next
                            </div>
                            <div>
                                <img src="/assets/images/arrow-right.svg" className="arrow-right" alt="arrow-right" />
                            </div>
                        </div>
                    </button>
                ) : (
                    <button className="btn btn-primary quiz-next btn-block" disabled="1">
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <div>
                                Next
                            </div>
                            <div>
                                <img src="/assets/images/arrow-right.svg" className="arrow-right" alt="arrow-right" />
                            </div>
                        </div>
                    </button>
                )}
            </div>
        );
    };

    return (
        <div style={{ minHeight: '100vh' }}>
            {question.section ? renderProgress() : null}
            <h2 className="quiz-home-hero-title">
                {question.title.includes('how old are you') ? `Hi, ${name}! How old are you?` : question.title}
            </h2>
            <h2 className="quiz-home-hero-subtitle">{question.subtitle}</h2>

            <div style={{ marginTop: '3em', marginBottom: '3em' }}>{renderOptions()}</div>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div className="row justify-content-center" style={{ marginTop: '27px' }}>{renderBack()}</div>
                <div className="row justify-content-center" >
                    {renderForward()}
                </div>

            </div>
        </div>
    );
};

export default ThyroidQuizQuestion;
