import React, { useState } from "react";

const DiagnosisTime = ({ handleChange }) => {
    const [value, setValue] = useState("");
    const [error, setError] = useState(0);

    const setAnswer = (answer) => {
        setValue(answer);
    };

    const handleNextClick = () => {
        if (value === "") {
            setError(1);
        } else {
            handleChange("diagnosis-time", { value, totalValue: 1 });
        }
    };
    const handleBackClick = () => {
        handleChange("diagnosis-time-back");
    }


    return (
        <div className="uvi-landing-main extra-padding">
            <div className="progress progress-md">
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "14%", backgroundColor: "#DC4A9A" }}
                    aria-valuenow="14"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
            <h2 className="quiz-home-hero-title">When were you diagnosed?</h2>

            <div className="row answer-section">
                <div className="col-12">
                    <div
                        className={
                            value === "Less than 6 months ago"
                                ? "quiz-options-selected"
                                : "quiz-options"
                        }
                        onClick={() => setAnswer("Less than 6 months ago")}
                    >
                        <i className={
                            value === "Less than 6 months ago" ? 'fa-solid fa-circle-dot' : 'fa-regular fa-circle'
                        }>
                        </i>&nbsp;&nbsp;
                        Less than 6 months ago
                    </div>
                </div>
                <div className="col-12">
                    <div
                        className={
                            value === "6 months to 1 year ago"
                                ? "quiz-options-selected"
                                : "quiz-options"
                        }
                        onClick={() => setAnswer("6 months to 1 year ago")}
                    >
                        <i className={
                            value === "6 months to 1 year ago" ? 'fa-solid fa-circle-dot' : 'fa-regular fa-circle'
                        }>
                        </i>&nbsp;&nbsp;
                        6 months to 1 year ago
                    </div>
                </div>
                <div className="col-12">
                    <div
                        className={
                            value === "1 to 3 years ago"
                                ? "quiz-options-selected"
                                : "quiz-options"
                        }
                        onClick={() => setAnswer("1 to 3 years ago")}
                    >
                        <i className={
                            value === "1 to 3 years ago" ? 'fa-solid fa-circle-dot' : 'fa-regular fa-circle'
                        }>
                        </i>&nbsp;&nbsp;
                        1 to 3 years ago
                    </div>
                </div>
                <div className="col-12">
                    <div
                        className={
                            value === "3+ years ago"
                                ? "quiz-options-selected"
                                : "quiz-options"
                        }
                        onClick={() => setAnswer("3+ years ago")}
                    >
                        <i className={
                            value === "3+ years ago" ? 'fa-solid fa-circle-dot' : 'fa-regular fa-circle'
                        }>
                        </i>&nbsp;&nbsp;
                        3+ years ago
                    </div>
                </div>
            </div>

            {error === 1 ? (
                <h4 className="mcq-error-alert">
                    Please select one of the options above
                </h4>
            ) : null}

            <div>
                <div className="row">
                    <div className="col-12">
                        <button
                            className="btn btn-primary quiz-next"
                            onClick={handleNextClick}
                        >
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <div>
                                    Next
                                </div>
                                <div>
                                    <img src="/assets/images/arrow-right.svg" className="arrow-right" alt="arrow-right" />
                                </div>
                            </div>
                        </button>
                        <button
                            className="btn btn-primary quiz-back"
                            onClick={handleBackClick}

                        >
                            Back
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiagnosisTime;
