import React, { useState } from "react";

const QuizHeightWeight = ({ handleChange }) => {
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [error, setError] = useState("");

    const handleHeightChange = (height) => {
        setHeight(height);
    };

    const handleWeightChange = (weight) => {
        setWeight(weight);
    };

    const handleNextClick = () => {
        function isNumeric(value) {
            return /^-?\d+$/.test(value);
        }

        if (!isNumeric(height) || !isNumeric(weight) || height.trim() === "" || weight === "") {
            setError("Please fill all details above before proceeding");
        } else if(!(height > 99 && height < 221) || !(weight > 30 && weight < 150)) {
            setError("Please add correct valued before proceeding");
        }
        else {
            handleChange("height-weight", { height, weight });
        }
    };
    const handleBackClick = () => {


        handleChange("height-weight-back");

    };

    return (
        <div className="uvi-landing-main extra-padding">
            <div className="progress progress-md">
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "21%", backgroundColor: "#DC4A9A" }}
                    aria-valuenow="21"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
            <h2 className="quiz-home-hero-title">
                Could you tell us your height and weight?
            </h2>

            <h4 className="quiz-home-hero-subtitle">
                We use this information to calculate your BMI, which is
                important to understand the effects of PCOS on your body
            </h4>

            <div className="form-group row mb-3">
                <div className="col-12">
                    <input
                        autoComplete="off"
                        type="text"
                        style={{ height: "48px" }}
                        className="form-control landing-page-form-element"
                        name="name"
                        placeholder="Enter your height in cms"
                        onChange={(e) => {
                            handleHeightChange(e.target.value);
                        }}
                        value={height}
                    />
                </div>
            </div>

            <div className="form-group row mb-3">
                <div className="col-12">
                    <input
                        autoComplete="off"
                        type="text"
                        style={{ height: "48px" }}
                        className="form-control landing-page-form-element"
                        name="name"
                        placeholder="Enter your weight in kgs"
                        onChange={(e) => {
                            handleWeightChange(e.target.value);
                        }}
                        value={weight}
                    />
                </div>
            </div>

            {error ? <h4 className="mcq-error-alert">{error}</h4> : null}

            <div>
                <div className="row">
                    <div className="col-12">
                        <button
                            className="btn btn-primary quiz-next"
                            onClick={handleNextClick}
                        >
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <div>
                                    Next
                                </div>
                                <div>
                                    <img src="/assets/images/arrow-right.svg" className="arrow-right" alt="arrow-right" />
                                </div>
                            </div>
                        </button>
                        <button
                            className="btn btn-primary quiz-back"
                            onClick={handleBackClick}>
                            Back
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuizHeightWeight;
