import { HOME_DATA } from "../../Actions/home";

const initState = [];

export const homeReducer = (state = initState, action) => {
    switch (action.type) {
        case HOME_DATA:
            return { ...state, homeData: action.payload };
        default:
            return state;
    }
};
