import React from 'react';

const ThyroidQuizHome = ({ handleChange }) => {
    const handleClick = () => {
        handleChange('hero', null);
    };

    return (
        <div className="uvi-landing-main">
            <h2 className="landing-home-hero-1">Thyroid Severity Quiz</h2>
            <h4 className="landing-home-hero-2">
                Let’s start by answering few questions which will help us determining your Thyroid Severity Score and
                pre-conditions causing it
            </h4>
            <h4 className="quiz-home-hero-1">
                Completing this quiz may take around 3 to 5 minutes of your time.
                Once you have finished taking the quiz, you will be immediately be able to unlock your results.
            </h4>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '2em',
                }}
            >
                <img src="/assets/images/uvi-line.svg" className="hero-img" alt="uvi-hero" />
            </div>
            <button className="btn btn-primary quiz-forward btn-block" onClick={handleClick}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <div>
                        Start Quiz
                    </div>
                    <div>
                        <img src="/assets/images/arrow-right.svg" className="arrow-right" alt="arrow-right" />
                    </div>
                </div>
            </button>
        </div>
    );
};

export default ThyroidQuizHome;
