import React, { useState, useEffect } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// import Stats from '../home/stats';
import LandingVideo from '../ui-elements/landing-video';
import Testimonials from '../ui-elements/testimonials';
// import WhatToExpect from '../ui-elements/uvi-blogs';


const QuizFinalResult = ({
    name,
    scores,
    quizScore,
    quizTotalScore,
    conceive,
    age,
    height,
    weight,
    diagnosisTime,
    mainSymptoms,
    currentMultiQuestions,
    friedFoods,
    foodAllergies,
    eatingDisorders,
    aesScore,
    cisScore,
    irsScore,
    assScore,
    finalScoreServer
}) => {
    let countmainSymptoms = mainSymptoms.split(',').length;
    //total score
    const finalScore = ((countmainSymptoms + cisScore + irsScore + assScore + aesScore + conceive) / 43 * 100)

    const [plan, setPlan] = useState('');
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        function updateSize() {
            setIsMobile(window.innerWidth < 500);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    const downloadMode = 0;
    const getTextColor = (score, scope) => {
        // return score > 66 ? '#EE5800' : score >= 33 ? '#f39c12' : '#89B08A';
        if (scope === 'cis' || scope === 'ci') {
            return score <= 28 ? '#89B08A' : score <= 67 ? '#f39c12' : '#EE5800';
        } else if (scope === 'irs' || scope === 'ir') {
            return score <= 30 ? '#89B08A' : score <= 60 ? '#f39c12' : '#EE5800';
        } else if (scope === 'ass' || scope === 'as') {
            return score <= 34 ? '#89B08A' : score <= 67 ? '#f39c12' : '#EE5800';
        } else if (scope === 'aes' || scope === 'ae') {
            return score <= 34 ? '#89B08A' : score <= 67 ? '#f39c12' : '#EE5800';
        }
    };
    const getLevel = (scope) => {
        if (scope === 'cis') {
            return scores.cisScore <= 28 ? 'Mild' : scores.cisScore <= 67 ? 'Moderate' : 'Severe';
        } else if (scope === 'irs') {
            return scores.irsScore <= 30 ? 'Mild' : scores.irsScore <= 60 ? 'Moderate' : 'Severe';
        } else if (scope === 'ass') {
            return scores.assScore <= 34 ? 'Mild' : scores.assScore <= 67 ? 'Moderate' : 'Severe';
        } else if (scope === 'aes') {
            return scores.aesScore <= 34 ? 'Mild' : scores.aesScore <= 67 ? 'Moderate' : 'Severe';
        }
    };

    const returnColor = (severity) => {
        if (severity === 'Mild') {
            return '#969CBC';
        } else if (severity === 'Moderate') {
            return '#F8CA27';
        } else if (severity === 'Severe') {
            return '#F5735A';
        }
    };

    const conditionDetails = {
        cis: {
            header: 'Chronic Inflammation',
            first: 'Chronic Inflammation is one of the markers of PCOS. It can be triggered by your diet or environment and can worsen the hormonal balance in your body.',
            second: '💡 Introduction of anti-inflammatory foods, and fitness routines are proven to lower chronic inflammation in women with PCOS.',
        },
        irs: {
            header: 'Insulin Resistance',
            first: 'Having Insulin Resistance means that your body is not doing a good job of processing glucose, the energy source for your body. High insulin can impair ovulation and lead to irregular periods.',
            second: '💡 A right diet and nutrition routine can help keep your insulin and blood sugars in check naturally.',
        },
        ass: {
            header: 'Adrenals and Stress',
            first: 'Adrenal hormones are responsible for regulation of metabolism, immune system and response to stress. 40% of women with PCOS produce excess adrenals.',
            second: '💡 Deep breathing, yoga and stress reduction techniques help in managing this root cause.',
        },
        aes: {
            header: 'Androgen Excess',
            first: 'Excess production of androgen, also referred to as “male hormone”, can contribute to abnormal body hair, acne, increase in weight and hair loss.',
            second: '💡 Depending on the severity of excess androgens in your body, this root cause can be managed with the right diet & anti-androgen medications.',
        },
    };

    useEffect(() => {
        if (conceive === 1) {
            setPlan('fertility');
        } else {
            const planLevel = (quizScore / quizTotalScore) * 100;

            if (planLevel > 25) {
                setPlan('advanced');
            } else {
                setPlan('basic');
            }
        }
    }, [quizScore, quizTotalScore, conceive]);

    const planDetails = {
        basic: {
            header: "Beginner's Plan",
            details: 'perfect when you want to start losing weight with PCOS',
            link: 'https://rzp.io/l/uvipcos1month',
        },
        advanced: {
            header: 'Advanced Plan',
            details: 'perfect when you want to conquer all PCOS symptoms holistically',
            link: 'https://rzp.io/l/uvipcos3month',
        },
        fertility: {
            header: 'Fertility Plan',
            details: 'perfect when you want to conceive with PCOS with specialized support',
            link: 'https://rzp.io/l/uvipcos6month',
        },
    };
    const renderPreConditions = (scope, score, type) => {
        return (
            <div className='preCond-container'>
                <div className='preCond-container-p1'>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img alt="pre-conditions" src={`/assets/images/pcos-report-score-${scope}.svg`} />
                    </div>
                </div>
                <div className='preCond-container-p2'>
                    <div className='progress-bar-container'>
                        <div style={{ height: isMobile ? 48 : 72, width: isMobile ? 48 : 72 }}>
                            <CircularProgressbar
                                styles={buildStyles({
                                    textColor: getTextColor(score, scope),
                                    pathColor: getTextColor(score, scope),
                                    trailColor: '#AAAAAA',
                                })}
                                value={score}
                                text={`${score.toFixed(0)}%`}
                            />
                        </div>
                        <h4 style={{ fontSize: 18, fontWeight: 500, color: '#373750', lineHeight: '1.5em' }}>
                            {getLevel(type)}
                        </h4>
                    </div>
                    <div className='preCond-content-container'>
                        <h4 style={{ fontSize: isMobile ? 18 : 24, fontWeight: 600, lineHeight: '32px', color: '#10202D', fontFamily: 'Montserrat' }}>
                            {preConditions[scope][0]}
                        </h4>
                        <h4 style={{ fontSize: isMobile ? 14 : 16, fontWeight: 300, lineHeight: isMobile ? '22px' : '24px', color: '#10202D', fontFamily: 'Merriweather Sans' }}>
                            {preConditions[scope][1]}
                        </h4>
                    </div>
                </div>
            </div>
        );
    };
    const preConditions = {
        ci: [
            'Inflammation',
            'Auto-immune conditions or chronic infections can lead to inflammation in the body. Your body releases anti-bodies that attack the thyroid cells, causing it to make less thyroid hormone leading to hypothyroidism.',
        ],
        ir: [
            'Insulin Resistance',
            'Having Insulin Resistance means that your body is not doing a good job of processing glucose, the energy source for your body. High insulin can impair ovulation and lead to irregular periods',
        ],
        as: [
            'Adrenals & Stress',
            'An overactive adrenal gland or high stress releases more Cortisol and androgens in the body like DHEA. Cortisol shuts down many functions of the body that are not important to prioritize, leading to a reduction in progesterone and estrogen',
        ],
        ae: [
            'Androgen Excess',
            'High androgens, also called “male hormone”, are a classic sign of PCOS. Excess production of androgen can contribute to abnormal body hair, acne, increase in weight and hair loss. Between 50-80% of women with high androgens have PCOS',
        ],

    };
    const pointsData = {
        ci: ['Add vitamin-rich foods in your diet', 'Do yoga & strength excercises', 'Eat nutrient dense foods'],
        ir: ['Eliminate trigger foods from your diet', 'Do yoga & strength excercises', 'Improve your body metabolism'],
        as: [
            'Practice deep breathing / meditation',
            'Do yoga & strength excercises',
            'Sleep 7-8 hours to keep stress in check',
        ],
        ae: ['Follow a right diet plan', 'Include anti-androgen medications', 'Improve dietary fibre intake'],

    };

    const renderPoints = (scope) => {
        return (
            <div
                className='renderpoints-container'

            >
                <div className='renderPoints' >
                    <div
                        className="col-12 col-md-4"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',

                            justifyContent: isMobile ? 'normal' : 'center',
                            marginTop: 10,
                            marginBottom: 10,
                            marginLeft: 10,
                        }}
                    >
                        <img
                            src={`/assets/images/pcos-report-${scope}-1.svg`}
                            alt={`thyroid-report-${scope}-1`}
                            style={{ height: 48, width: 48, marginRight: 10 }}
                        />

                        <h3 style={{ fontSize: 16, fontWeight: 500, color: '#373750' }}>
                            {pointsData[scope][0]}
                        </h3>
                    </div>

                    <div
                        className="col-12 col-md-4"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            // justifyContent: 'center',
                            marginTop: 10,
                            marginBottom: 10,
                            marginLeft: 10,
                        }}
                    >
                        <img
                            src={`/assets/images/pcos-report-${scope}-2.svg`}
                            alt={`thyroid-report-${scope}-2`}
                            style={{ height: 48, width: 48, marginRight: 10 }}
                        />

                        <h3 style={{ fontSize: 16, fontWeight: 500, color: '#373750' }}>
                            {pointsData[scope][1]}
                        </h3>
                    </div>

                    <div
                        className="col-12 col-md-4"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            // justifyContent: 'center',
                            marginTop: 10,
                            marginBottom: 10,
                            marginLeft: 10,
                        }}
                    >
                        <img
                            src={`/assets/images/pcos-report-${scope}-3.svg`}
                            alt={`thyroid-report-${scope}-3`}
                            style={{ height: 48, width: 48, marginRight: 10 }}
                        />

                        <h3 style={{ fontSize: 16, fontWeight: 500, color: '#373750' }}>
                            {pointsData[scope][2]}
                        </h3>
                    </div>
                </div>
            </div >
        );
    };

    const renderConditions = (type, scope, score, bgcolor) => {
        return (
            <div
                className='renderConditions-container'
                style={{ marginTop: '1em', marginBottom: '1em', paddingLeft: 0, paddingRight: 0 }}
            >
                <div className="cond-1" style={{ backgroundColor: bgcolor, borderTopLeftRadius: 12, borderTopRightRadius: 12 }}>
                    <img
                        src={`/assets/images/pcos-report-pc-${scope}.svg`}
                        alt={`thyroid-report-pc-${scope}`}
                    />
                    <h4
                        style={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: '#FFFFFF',
                            minHeight: 35,
                            textAlign: 'center',
                            marginTop: 12
                        }}
                    >
                        {conditionDetails[type].header}
                    </h4>
                </div>
                <div
                    style={{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid rgba(55, 55, 80, 0.2)',
                        marginTop: 0,
                        borderBottomLeftRadius: 12,
                        borderBottomRightRadius: 12,
                        padding: 20,
                    }}
                >
                    <div className='progressbar-container'>
                        <div style={{ maxWidth: "30%" }}>
                            <div style={{ height: 48, width: 48 }}>
                                <CircularProgressbar
                                    styles={buildStyles({
                                        textColor: getTextColor(score, type),
                                        pathColor: getTextColor(score, type),
                                        trailColor: '#AAAAAA',
                                    })}
                                    value={score}
                                    text={`${score.toFixed(0)}%`}
                                />
                            </div>
                        </div>

                        <div>
                            <h4 style={{ fontSize: 16, fontWeight: 400, color: '#10202D', fontFamily: 'Merriweather Sans' }}>
                                {getLevel(type)}
                            </h4>
                        </div>
                    </div>
                </div>
            </div >





            // <div className="col-12">
            //     <div className="final-result-card">
            //         <h3 className="final-result-card-header">{conditionDetails[type].header}</h3>
            //         <h3
            //             className="final-result-card-score"
            //             style={{ color: returnColor(getLevel(type)) }}
            //         >{`${score.toFixed(0)}%`}</h3>
            //         <h3 className="final-result-card-level">{getLevel(type)}</h3>
            //     </div>
            // </div>
        );
    };

    return (
        <div className="uvi-landing-main">

            <div>
                <div className="row" style={{ marginTop: '2em', gap: '33px', justifyContent: 'center' }}>
                    <div
                        className="col-12 col-md-4"
                        style={{ backgroundColor: ' #275070', borderRadius: 12, borderBottomLeftRadius: 12, maxHeight: '372px', maxWidth: '326px', paddingLeft: "28px", paddingRight: "28px", paddingBottom: "20px" }}
                    >
                        <center>
                            <h4

                                style={{
                                    fontFamily: 'Montserrat',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: '20px',
                                    lineHeight: '28px',

                                    letterSpacing: '0.25px',

                                    /* lace */

                                    color: '#FFF5F5',

                                    marginTop: '2em',
                                    marginBottom: '2em',
                                }}
                            >

                                Your PCOS Severity is
                            </h4>

                            <div style={{ height: 140, width: 140 }}>
                                <CircularProgressbar
                                    styles={buildStyles({
                                        textColor: '#FFFFFF',
                                        pathColor: '#FFFFFF',
                                        trailColor: '#AAAAAA',
                                    })}
                                    value={finalScoreServer || finalScore}
                                    text={`${finalScoreServer || finalScore.toFixed(0)}%`}
                                />
                            </div>

                            <div
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: 11,
                                    padding: 17,
                                    marginTop: '2em',
                                    marginBottom: '1em',
                                }}
                            >
                                <div style={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: '#373750',
                                    fontWeight: 300,
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    /* identical to box height, or 150% */
                                    letterSpacing: '0.01em',
                                    color: '#10202D',
                                    marginLeft: '0px',
                                    marginRight: '0px',

                                }}>
                                    <i className="fa-solid fa-circle-exclamation" style={{ color: 'orange' }}></i>
                                    &nbsp; Your PCOS Health needs attention
                                </div>
                            </div>
                        </center>
                    </div>
                    {!isMobile && (<div
                        className="col-12 col-md-8"
                        style={{
                            backgroundColor: 'white',

                            border: '1px solid rgba(0, 0, 0, 0.1)',
                            borderRadius: 12,
                            padding: 20,
                            maxHeight: '372px',
                        }}
                    >
                        <div className="row justify-content-center align-items-center">
                            <div className="col-3">
                                <h3 style={{ fontSize: 16, fontWeight: 400, color: '#DC4A9A', fontFamily: 'Open Sans' }}>Name</h3>
                                <h4 style={{ fontSize: 18, fontWeight: 600, color: ' #10202D', fontFamily: 'Montserrat' }}>{name}</h4>
                            </div>

                            <div className="col-4">
                                {/* <h3 style={{ fontSize: 16, fontWeight: 400, color: '#DC4A9A', fontFamily: 'Open Sans' }}>Diagnosed</h3> */}
                                <h4 style={{ fontSize: 16, fontWeight: 300, color: ' #10202D', fontFamily: 'Merriweather Sans' }}>
                                    {/* {diagnosed} */}
                                </h4>
                            </div>

                            <div className="col-5" style={{}}>
                                <a
                                    className="btn btn-primary btn-block hc-button-3"
                                    href="https://uvihealth.in/health-coach?utm_source=pcosquiz&utm_medium=website"
                                >
                                    Consult Health Coach &nbsp;
                                    <i className="fa-solid fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-4">
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'left',
                                    }}
                                >
                                    <div style={{ marginRight: 10 }}>
                                        <img src="/assets/images/thyroid/hero-age.svg" alt="hero-age" />
                                    </div>
                                    <div>
                                        <h3 style={{ fontSize: 16, fontWeight: 400, color: '#DC4A9A', fontFamily: 'Open Sans' }}>Age</h3>
                                        <h4
                                            style={{ fontSize: 18, fontWeight: 600, color: ' #10202D', fontFamily: 'Montserrat' }}
                                        >{`${age} years`}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-4">
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'left',
                                    }}
                                >
                                    <div style={{ marginRight: 10 }}>
                                        <img src="/assets/images/thyroid/hero-height.svg" alt="hero-height" />
                                    </div>
                                    <div>
                                        <h3 style={{ fontSize: 16, fontWeight: 400, color: '#DC4A9A', fontFamily: 'Open Sans' }}>Height</h3>
                                        <h4
                                            style={{ fontSize: 18, fontWeight: 600, color: ' #10202D', fontFamily: 'Montserrat' }}
                                        >{`${height} cms`}</h4>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'left',
                                    }}
                                >
                                    <div style={{ marginRight: 10 }}>
                                        <img src="/assets/images/thyroid/hero-weight.svg" alt="hero-weight" />
                                    </div>
                                    <div>
                                        <h3 style={{ fontSize: 16, fontWeight: 400, color: '#DC4A9A', fontFamily: 'Open Sans' }}>Weight</h3>
                                        <h4
                                            style={{ fontSize: 18, fontWeight: 600, color: ' #10202D', fontFamily: 'Montserrat' }}
                                        >{`${weight} kgs`}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: '20px', marginBottom: '1em', display: 'flex' }}>
                            <h3 style={{ fontSize: 20, fontWeight: 600, color: ' #10202D', fontFamily: 'Montserrat' }}>Symptoms</h3>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {
                                mainSymptoms.includes('Weight Gain') ?
                                    <div style={{ marginRight: '40px' }}>
                                        <center>
                                            <img
                                                src="/assets/images/thyroid/thyroid-report-hero-1.svg"
                                                alt="thyroid-report-hero-1"
                                            />
                                            <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D', maxWidth: "121px" }}>Weight Gain</h4>
                                        </center>
                                    </div> : null
                            }

                            {
                                mainSymptoms.includes('Irregular periods') ?
                                    <div style={{ marginRight: '40px' }}>
                                        <center>
                                            <img
                                                src="/assets/images/thyroid/thyroid-report-hero-2.svg"
                                                alt="thyroid-report-hero-2"
                                            />
                                            <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D', maxWidth: "121px" }}>Irregular periods</h4>
                                        </center>
                                    </div> : null
                            }
                            {
                                mainSymptoms.includes('Acne') ?
                                    <div style={{ marginRight: '40px' }}>
                                        <center>
                                            <img
                                                src="/assets/images/pcos-report-hero-3.svg"
                                                alt="pcos-report-hero-3"
                                            />
                                            <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D', maxWidth: "121px" }}>Acne</h4>
                                        </center>
                                    </div> : null
                            }

                            {
                                mainSymptoms.includes('Hair Loss') ?
                                    <div style={{ marginRight: '40px' }}>
                                        <center>
                                            <img
                                                src="/assets/images/pcos-report-hero-4.svg"
                                                alt="pcos-report-hero-4"
                                            />
                                            <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D', maxWidth: "121px" }}>
                                                Hair Loss
                                            </h4>
                                        </center>
                                    </div> : null
                            }
                            {
                                mainSymptoms.includes('Abnormal Hair Growth') ?
                                    <div style={{ marginRight: '40px' }}>
                                        <center>
                                            <img
                                                src="/assets/images/pcos-report-hero-5.svg"
                                                alt="pcos-report-hero-5"
                                            />
                                            <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D', maxWidth: "121px" }}>
                                                Abnormal Hair Growth
                                            </h4>
                                        </center>
                                    </div> : null
                            }
                            {
                                mainSymptoms.includes('Mood swings') ?
                                    <div style={{ marginRight: '0px' }}>
                                        <center>
                                            <img
                                                src="/assets/images/pcos-report-hero-6.svg"
                                                alt="pcos-report-hero-6"
                                            />
                                            <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D', maxWidth: "121px" }}>
                                                Mood swings
                                            </h4>
                                        </center>
                                    </div> : null
                            }
                        </div>
                    </div>)}
                </div>
            </div >
            {/* <h2 className="quiz-report-hero-1">
                {`${name}'s`} <br /> PCOS Root Cause Analysis
            </h2>

            <h4 className="quiz-report-hero-2">Key Insights</h4> */}

            {/* <div className="row quiz-report-user-stats">
                <div className="col-4 quiz-report-user-stats-section-1">
                    <div className="quiz-report-user-stats-number">{age}</div>
                    <div className="quiz-report-user-stats-text">years</div>
                </div>
                <div className="col-4 quiz-report-user-stats-section-2">
                    <div className="quiz-report-user-stats-number">{height}</div>
                    <div className="quiz-report-user-stats-text">cms</div>
                </div>
                <div className="col-4 quiz-report-user-stats-section-3">
                    <div className="quiz-report-user-stats-number">{weight}</div>
                    <div className="quiz-report-user-stats-text">kgs</div>
                </div>

                <div className="col-12">
                    <h4 className="quiz-report-diagnosis-time">{`Diagnosed ${diagnosisTime}`}</h4>

                    <h4 className="quiz-report-diagnosis-time">{`Symptoms: ${mainSymptoms.split(',').join(', ')}`}</h4>
                </div>
            </div> */}

            {/* <div className="row">


                <div className="col-12 disclaimer-section">
                    <p className="disclaimer-text">
                        Disclaimer: Your root cause analysis is meant to be a guidance tool and should not be trated as
                        an alternative to PCOS diagnosis or consult with a licensed medical provider.
                    </p>
                </div>

                <div className="col-12">
                    <p className="final-result-contact">
                        Call{' '}
                        <span
                            style={{
                                color: '#f5735a',
                            }}
                        >
                            080-4568-5333
                        </span>{' '}
                        for more information
                    </p>
                </div>
            </div> */}
            <div>
                <div className="row justify-content-center" style={{ marginTop: '80px', marginBottom: '1em' }}>
                    <div className='pre-condition' style={{ paddingBottom: isMobile ? '32px' : '41px' }}>
                        Your PCOS Pre-Conditions at a Glance
                    </div>
                    <div className='pre-condition-cards'>
                        {renderConditions('cis', 'ci', scores.cisScore, '#668CA1')}
                        {renderConditions('irs', 'ir', scores.irsScore, '#9073A2')}
                        {renderConditions('ass', 'as', scores.assScore, '#B88167')}
                        {renderConditions('aes', 'ae', scores.aesScore, '#8E6182')}

                    </div>
                </div>
            </div>

            <div style={{
                marginTop: isMobile ? '64px' : '80px', marginBottom: '3em'
            }}>
                <div className="row justify-content-center">
                    < h3
                        className='precondition'
                        style={{ paddingBottom: isMobile ? '32px' : '48px', justifyContent: isMobile ? "normal" : "center" }}
                    >
                        Your PCOS Pre-Conditions Explained
                    </h3>
                </div>

                {renderPreConditions('ci', scores.cisScore)}
                {renderPoints('ci')}
                {renderPreConditions('ir', scores.irsScore)}
                {renderPoints('ir')}
                {renderPreConditions('as', scores.assScore)}
                {renderPoints('as')}
                {renderPreConditions('ae', scores.aesScore)}
                {renderPoints('ae')}

                {/* {renderPreConditions('gd', gdScore)}
                {renderPoints('gd')}

                {renderPreConditions('ms', msScore)}
                {renderPoints('ms')}

                {renderPreConditions('nd', ndScore)}
                {renderPoints('nd')}

                {renderPreConditions('ge', geScore)}
                {renderPoints('ge')} */}
            </div >

            <div style={{ marginTop: '8em', marginBottom: '3em' }}>
                <div className='help-container'>
                    <div className='help-head'>
                        Have any questions? Book a call with us
                    </div>
                    <div className='btn-container'>
                        <a
                            className="btn btn-primary btn-lg hc-button-1"
                            href="https://uvihealth.in/health-coach?utm_source=pcosquiz&utm_medium=website"
                        >
                            Talk to Health Coach
                        </a>
                    </div>
                </div>
                {/* <div className="row justify-content-center">
                    <h3 style={{ fontSize: 28, fontWeight: 600, color: ' #10202D', fontFamily: 'Montserrat', lineHeight: '1.5em' }}>
                        Have any questions? Book a call with us
                    </h3>
                </div>
                <div className="row justify-content-center" style={{ marginTop: '2em' }}>
                    <a
                        className="btn btn-primary btn-lg hc-button-1"
                        href="https://uvihealth.in/health-coach?utm_source=pcosquiz&utm_medium=website"
                    >
                        Talk to Health Coach
                    </a>
                </div> */}
            </div>
            <div style={{ marginTop: '8em', marginBottom: '3em' }}>
                <div className='playstore-conatainer'>
                    <div className='playstore-content-1'>
                        <div className='playstore-head'>
                            We make it easy for you to
                            prioritise your health
                        </div >
                        <div className='playstore-subhead'>
                            Experience live & interactive fitness sessions, nutritionist & doctor consults & get easy access to at-home diagnostics
                        </div>
                        <div className='playstore-data'>
                            <div className='playstore-datas'>

                                <div className='playstore-datas-p1'>
                                    4.8
                                </div>
                                <div className='playstore-datas-p2'>
                                    Rated on Play Store
                                </div>
                            </div>
                            <div className='playstore-datas'>
                                <div className='playstore-datas-p1'>
                                    90%+
                                </div>
                                <div className='playstore-datas-p2'>
                                    Positive Results
                                </div>
                            </div>
                            <div className='playstore-datas'>
                                <div className='playstore-datas-p1'>
                                    50k+
                                </div>
                                <div className='playstore-datas-p2'>
                                    Happy Users
                                </div>
                            </div>
                        </div>
                        <div className='store-btns'>
                            <a href='https://play.google.com/store/apps/details?id=com.uvihealth.app&hl=en_IN&gl=US'>
                                <img className='store-btns-1' src='/assets/images/google-play-btn.png' alt='playstore' />
                            </a>
                            <a href='https://apps.apple.com/in/app/uvi-health/id1521502013'>
                                <img className='store-btns-2' src='/assets/images/appstore-btn.png' alt='appstore' />
                            </a>
                        </div>
                    </div>
                    <div className='playstore-content-2'>
                        <img className='phone-img' src='/assets/images/phone-1.png' alt='phone-1' />
                        <img className='phone-img' src='/assets/images/phone-2.png' alt='phone-2' />
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-12 col-md-7"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >

                    </div>

                    {/* <div className="col-12 col-md-5">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                src="/assets/images/thyroid/thyroid-report-app-2.png"
                                style={{ height: 300, marginRight: 10 }}
                            />
                            <img
                                src="/assets/images/thyroid/thyroid-report-app-1.png"
                                style={{ height: 300, marginLeft: 10 }}
                            />
                        </div>
                    </div> */}
                </div>
            </div>
        </div >
    );
};

export default QuizFinalResult;
