import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Home from './Components/home';
import Report from './Components/home/report';

import ThyroidQuiz from './Components/thyroid';
import ThyroidFinalReport from './Components/thyroid/final-report';

function App() {
    return (
        <>
            <div className="content-page">
                <Router>
                    <Switch>
                        <Route path="/thyroid/report/:id" component={ThyroidFinalReport} />
                        <Route path="/thyroid" component={ThyroidQuiz} />
                        <Route path="/rootcause" component={Home} />
                        <Route path="/report/:id" component={Report} />
                        <Route path="/pcos" component={Home} />
                        {/* <Route path="/" exact component={Home} /> */}
                        <Route path='/' component={() => {
                            window.location.replace('https://uvihealth.in/quiz')
                            return null;
                        }} />
                    </Switch>
                </Router>
            </div>
        </>
    );
}

export default App;
