/* eslint-disable default-case */
import { apiRequest } from "../../../Actions/api"
import { LOGIN, LOGIN_AUTH, LOGOUT, LOGOUT_AUTH } from "../../../Actions/login"
import {ROOT_URL} from '../../../Config/rootUrl'

export const loginMiddleWare = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case LOGIN_AUTH:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/admin/login`,
                method:'post',
                feature:LOGIN
            }))
            break;
        case LOGOUT_AUTH:
            dispatch(apiRequest({
                body:null,
                config:{},
                url:`${ROOT_URL}/admin/logout`,
                method:'get',
                feature:LOGOUT
            }))
    }
}