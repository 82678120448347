// feature name
export const HOME = `[HOME]`;

//action types
export const HOME_DATA = `${HOME} DATA`;

//action creator
export const setHomeData = data => ({
    type: HOME_DATA,
    payload: data
});
