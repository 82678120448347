import React, { useState, useEffect } from 'react';
import {useHistory } from 'react-router-dom';
import request from 'request';
import ReactGA from 'react-ga';
import queryString from 'query-string';

import ThyroidQuizHome from './quiz-home';
import ThyroidQuizQuestion from './quiz-question';
import ThyroidReport from './report';

import Config from './thyroid-config';

const Home = () => {
    let history = useHistory();
    const userId = 'UVITRAQ' + String(Date.now());
    const [quizMode, setQuizMode] = useState(0);
    const [tracker, setTracker] = useState(0);
    const { utm_source, utm_medium, utm_campaign } = queryString.parse(window.location.search);

    const [currentQuestion, setCurrentQuestion] = useState({});
    const [response, setResponse] = useState({});

    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [height, setHeight] = useState('');
    const [weight, setWeight] = useState('');
    const [concern, setConcern] = useState('');
    const [diagnosed, setDiagnosed] = useState('');

    const [ciScore, setCIScore] = useState(0);
    const [gdScore, setGDScore] = useState(0);
    const [msScore, setMSScore] = useState(0);
    const [ndScore, setNDScore] = useState(0);
    const [geScore, setGEScore] = useState(0);

    useEffect(() => {
        if (tracker === 0) {
            ReactGA.initialize('UA-201300789-1', {
                standardImplementation: true,
                titleCase: false,
                gaOptions: {
                    name: 'landingtracker',
                    userId: userId,
                    siteSpeedSampleRate: 100,
                },
            });
            setTracker(1);
        }
    }, [tracker]);

    const calculateScore = (r) => {
        let ciScore = 0;
        let gdScore = 0;
        let msScore = 0;
        let ndScore = 0;
        let geScore = 0;

        if (r['diagnosis-status'].includes('Hyperthyroidism')) {
            ciScore =
                ((r['hyperthyroidism-chronic-inflammation-1'] +
                    r['hyperthyroidism-chronic-inflammation-2'] +
                    r['hyperthyroidism-chronic-inflammation-3'] +
                    r['hyperthyroidism-chronic-inflammation-4']) /
                    12) *
                100;
            gdScore =
                ((r['hyperthyroidism-gut-health-1'] +
                    r['hyperthyroidism-gut-health-2'] +
                    r['hyperthyroidism-gut-health-3']) /
                    9) *
                100;
            msScore =
                ((r['hyperthyroidism-metabolic-stress-1'] +
                    r['hyperthyroidism-metabolic-stress-2'] +
                    r['hyperthyroidism-metabolic-stress-3'] +
                    r['hyperthyroidism-metabolic-stress-4']) /
                    23) *
                100;
            ndScore =
                ((r['hyperthyroidism-nutritional-deficiencies-1'] + r['hyperthyroidism-nutritional-deficiencies-2']) /
                    7) *
                100;
            geScore =
                ((r['hyperthyroidism-genetics-1'] +
                    r['hyperthyroidism-genetics-2'] +
                    r['hyperthyroidism-genetics-3'] +
                    r['hyperthyroidism-genetics-4']) /
                    6) *
                100;
        } else if (r['diagnosis-status'].includes('Hypothyroidism')) {
            ciScore =
                ((r['hypothyroidism-chronic-inflammation-1'] +
                    r['hypothyroidism-chronic-inflammation-2'] +
                    r['hypothyroidism-chronic-inflammation-3']) /
                    9) *
                100;
            gdScore =
                ((r['hypothyroidism-gut-health-1'] +
                    r['hypothyroidism-gut-health-2'] +
                    r['hypothyroidism-gut-health-3']) /
                    10) *
                100;
            msScore =
                ((r['hypothyroidism-metabolic-stress-1'] +
                    r['hypothyroidism-metabolic-stress-2'] +
                    r['hypothyroidism-metabolic-stress-3'] +
                    r['hypothyroidism-metabolic-stress-4']) /
                    21) *
                100;
            ndScore =
                ((r['hypothyroidism-nutritional-deficiencies-1'] +
                    r['hypothyroidism-nutritional-deficiencies-2'] +
                    r['hypothyroidism-nutritional-deficiencies-3'] +
                    r['hypothyroidism-nutritional-deficiencies-4']) /
                    14) *
                100;
            geScore =
                ((r['hypothyroidism-genetics-1'] +
                    r['hypothyroidism-genetics-2'] +
                    r['hypothyroidism-genetics-3'] +
                    r['hypothyroidism-genetics-4']) /
                    6) *
                100;
        }

        ciScore = String(ciScore.toFixed(0));
        gdScore = String(gdScore.toFixed(0));
        msScore = String(msScore.toFixed(0));
        ndScore = String(ndScore.toFixed(0));
        geScore = String(geScore.toFixed(0));

        setName(r['name']);
        setAge(r['age']);
        setHeight(r['height']);
        setWeight(r['weight']);
        setConcern(r['diagnosis-status']);
        setDiagnosed(r['diagnosed-time']);

        setCIScore(ciScore);
        setGDScore(gdScore);
        setMSScore(msScore);
        setNDScore(ndScore);
        setGEScore(geScore);

        const outputData = {
            userId: userId,
            name: r['name'] || '',
            mobileNumber: r['mobileNumber'] ? r['mobileNumber'] : '',
            age: r['age'] || '',
            height: r['height'] || '',
            weight: r['weight'] || '',
            concern: r['diagnosis-status'] || '',
            diagnosed: r['diagnosed-time'] || '',
            ciScore,
            gdScore,
            msScore,
            ndScore,
            geScore,
            utmSource: utm_source || 'NA',
            utmMedium: utm_medium || 'NA',
            utmCampaign: utm_campaign || 'NA',
            mainSymptoms: r['main-symptoms'] || '',
        };

        var options = {
            method: 'POST',
            url: 'https://uvi-api.uvihealth.in/insertThyroidQuiz',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            form: outputData,
        };

        request(options, function (error, response, body) {
            if (error) {
                console.log(error);
            } else {
                history.push(`/thyroid/report/${userId}`)
            }
            // setQuizProgress(quizProgress + 1);
        });

        setQuizMode(2);
    };

    const handleDataFromQuizlets = (context, data) => {
        if (context === 'hero') {
            setQuizMode(1);
            setCurrentQuestion(Config[0]);
        } else if (context === 'question') {
            const currentResponse = JSON.parse(JSON.stringify(response));
            currentResponse[data.topic] = data.data;
            setResponse(currentResponse);

            if (data.next === 'final') {
                calculateScore(currentResponse);
            } else {
                const nextQuestion = Config.filter((item) => item.id === data.next);
                setCurrentQuestion(nextQuestion[0]);
            }
        }
    };

    const returnQuizSection = () => {
        //quizMode
        switch (quizMode) {
            case 0:
                return (
                    <div className="container" id="container">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className='quiz-navbar-container'>
                                <img src="/assets/images/uvi-logo.svg" alt="uvi-logo" />
                            </div>
                        </div>
                        <ThyroidQuizHome handleChange={handleDataFromQuizlets} />
                    </div>
                );
            case 1:
                return (
                    <div className="container" id="container">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className='quiz-navbar-container'>
                                <img src="/assets/images/uvi-logo.svg" alt="uvi-logo" />
                            </div>
                        </div>
                        <ThyroidQuizQuestion
                            name={response.name ? response.name : ''}
                            question={currentQuestion}
                            handleChange={handleDataFromQuizlets}
                        />
                    </div>
                );
            case 2:
                return (
                    <div className="container2" id="container">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className='quiz-navbar-container'>
                                <img src="/assets/images/uvi-logo.svg" alt="uvi-logo" />
                            </div>
                        </div>

                        <ThyroidReport
                            name={name}
                            age={age}
                            height={height}
                            weight={weight}
                            concern={concern}
                            diagnosed={diagnosed}
                            ciScore={ciScore}
                            gdScore={gdScore}
                            msScore={msScore}
                            ndScore={ndScore}
                            geScore={geScore}
                        />
                    </div>
                );
            default:
                return (
                    <div className="container" id="container">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <div className='quiz-navbar-container'>
                                <img src="/assets/images/uvi-logo.svg" alt="uvi-logo" />
                            </div>
                        </div>
                        {/*as */}
                        <ThyroidQuizHome handleChange={handleDataFromQuizlets} />
                    </div>
                );
        }
    };

    return <div>{returnQuizSection()}</div>;
};

export default Home;
