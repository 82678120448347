import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

const ThyroidReport = ({
    name,
    age,
    height,
    weight,
    concern,
    diagnosed,
    ciScore,
    gdScore,
    msScore,
    ndScore,
    geScore,
    mainSymptoms
}) => {
    const finalScore =
        (parseFloat(ciScore) + parseFloat(gdScore) + parseFloat(msScore) + parseFloat(ndScore) + parseFloat(geScore)) /
        5;
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        function updateSize() {
            setIsMobile(window.innerWidth < 500);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const getTextColor = (score) => {
        return score > 66 ? '#EE5800' : score >= 33 ? '#f39c12' : '#89B08A';
    };

    const getSeverity = (score) => {
        return score > 66 ? 'Severe' : score >= 33 ? 'Moderate' : 'Mild';
    };

    const pointsData = {
        ci: ['Add anti-inflammatory foods', 'Practice elimination diet under guidance', 'Eat nutrient dense foods'],
        gd: ['Avoid Dairy Products', 'Cut processed/ high sugar foods', 'Eat nutrient dense foods'],
        ms: [
            'Practice deep breathing / meditation',
            'Do yoga & strength excercises',
            'Sleep 7-8 hours to keep stress in check',
        ],
        nd: ['Add supplements to your diet', 'Include magnesium rich food', 'Improve dietary fibre intake'],
        ge: ['Limit Toxin Exposure', 'Cut down plastic use', 'Sleep 7-8 hours to keep stress in check'],
    };

    const renderPoints = (scope) => {
        return (
            <div
                className='renderpoints-container'

            >
                <div className='renderPoints' >
                    <div
                        className="col-12 col-md-4"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 10,
                            marginBottom: 10,
                            marginLeft: 10,
                        }}
                    >
                        <img
                            src={`/assets/images/thyroid/thyroid-report-${scope}-1.svg`}
                            alt={`thyroid-report-${scope}-1`}
                            style={{ height: 48, width: 48, marginRight: 10 }}
                        />

                        <h3 style={{ fontSize: 16, fontWeight: 500, color: '#373750' }}>{pointsData[scope][0]}</h3>
                    </div>

                    <div
                        className="col-12 col-md-4"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            // justifyContent: 'center',
                            marginTop: 10,
                            marginBottom: 10,
                            marginLeft: 10,
                        }}
                    >
                        <img
                            src={`/assets/images/thyroid/thyroid-report-${scope}-2.svg`}
                            alt={`thyroid-report-${scope}-2`}
                            style={{ height: 48, width: 48, marginRight: 10 }}
                        />

                        <h3 style={{ fontSize: 16, fontWeight: 500, color: '#373750' }}>{pointsData[scope][1]}</h3>
                    </div>

                    <div
                        className="col-12 col-md-4"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            // justifyContent: 'center',
                            marginTop: 10,
                            marginBottom: 10,
                            marginLeft: 10,
                        }}
                    >
                        <img
                            src={`/assets/images/thyroid/thyroid-report-${scope}-3.svg`}
                            alt={`thyroid-report-${scope}-3`}
                            style={{ height: 48, width: 48, marginRight: 10 }}
                        />

                        <h3 style={{ fontSize: 16, fontWeight: 500, color: '#373750' }}>{pointsData[scope][2]}</h3>
                    </div>
                </div>
            </div >
        );
    };

    const preConditions = {
        ci: [
            'Inflammation',
            'Auto-immune conditions or chronic infections can lead to inflammation in the body. Your body releases anti-bodies that attack the thyroid cells, causing it to make less thyroid hormone leading to hypothyroidism.',
        ],
        gd: [
            'Gut Health',
            'Irritable bowel, diarrhoea, food intolerances or constipation are the biggest signs of leaky gut, i.e. presence of harmful microbes in the digestive tract. Gut health and thyroid are closely inter-connected as your gut houses the most immune cells in the body.',
        ],
        ms: [
            'Metabolic Stress',
            'Chronic stress causes your body to remain in a "fight or flight" state. This increases stress hormones like cortisol, which can interfere with thyroid production and can flare up your symptoms.',
        ],
        nd: [
            'Nutritional Deficiencies',
            'Common nutritional deficiencies of Vitamin D, Vitamin B12, Vitamin A, Vitamin B2, Vitamin C, Magnesium, or Zinc can contribute to severity of hypothyroidism.',
        ],
        ge: [
            'Genetics & Environment',
            'Genetic factors play a role in more than 75% of the thyroid cases. Moreover, exposure to toxins like mercury, fluoride and fertilizers have been found to affect thyroid function by recruiting antibodies to attack the thyroid.',
        ],
    };

    const renderPreConditions = (scope, score) => {
        return (


            <div className='preCond-container'>
                <div className='preCond-container-p1'>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img alt="pre-conditions" src={`/assets/images/thyroid/thyroid-report-score-${scope}.svg`} />
                    </div>
                </div>
                <div className='preCond-container-p2'>
                    <div className='progress-bar-container'>
                        <div style={{ height: isMobile ? 48 : 72, width: isMobile ? 48 : 72 }}>
                            <CircularProgressbar
                                styles={buildStyles({
                                    textColor: getTextColor(score),
                                    pathColor: getTextColor(score),
                                    trailColor: '#AAAAAA',
                                })}
                                value={score}
                                text={`${score}%`}
                            />
                        </div>
                        <h4 style={{ fontSize: 18, fontWeight: 500, color: '#373750', lineHeight: '1.5em' }}>
                            {getSeverity(score)}
                        </h4>
                    </div>
                    <div className='preCond-content-container'>
                        <h4 style={{ fontSize: isMobile ? 18 : 24, fontWeight: 600, lineHeight: '32px', color: '#10202D', fontFamily: 'Montserrat' }}>
                            {preConditions[scope][0]}
                        </h4>
                        <h4 style={{ fontSize: isMobile ? 14 : 16, fontWeight: 300, lineHeight: isMobile ? '22px' : '24px', color: '#10202D', fontFamily: 'Merriweather Sans' }}>
                            {preConditions[scope][1]}
                        </h4>
                    </div>
                </div>
            </div>
        );

        // return (
        //     <div className="row" style={{ marginTop: '1em', marginBottom: '2em', gap: '26px', justifyContent: 'center' }}>
        //         <div
        //             className="col-12 col-md-4"
        //             style={{ backgroundColor: 'rgba(248, 219, 235, 0.4)', borderTopLeftRadius: 12, borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopRightRadius: 12, maxWidth: '335px' }}
        //         >
        //             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        //                 <img alt="pre-conditions" src={`/assets/images/thyroid/thyroid-report-score-${scope}.svg`} />
        //             </div>
        //         </div>

        //         <div className="col-12 col-md-8 pre-cond-1" style={{ border: ' 1px solid rgba(55, 55, 80, 0.2)', borderRadius: 12 }}>
        //             <div className="row align-items-center justify-content-center">
        //                 <div className="col-4 pre-cond-2">
        //                     <div style={{ height: 72, width: 72 }}>
        //                         <CircularProgressbar
        //                             styles={buildStyles({
        //                                 textColor: getTextColor(score),
        //                                 pathColor: getTextColor(score),
        //                                 trailColor: '#AAAAAA',
        //                             })}
        //                             value={score}
        //                             text={`${score}%`}
        //                         />
        //                     </div>
        //                     <h4 style={{ fontSize: 18, fontWeight: 500, color: '#373750', lineHeight: '1.5em' }}>
        //                         {getSeverity(score)}
        //                     </h4>
        //                 </div>

        //                 <div className="col-7" style={{ marginLeft: 10 }}>
        //                     <h4 style={{ fontSize: 24, fontWeight: 600, lineHeight: '32px', color: '#10202D', fontFamily: 'Montserrat' }}>
        //                         {preConditions[scope][0]}
        //                     </h4>
        //                     <h4 style={{ fontSize: 16, fontWeight: 300, lineHeight: '24px', color: '#10202D', fontFamily: 'Merriweather Sans' }}>
        //                         {preConditions[scope][1]}
        //                     </h4>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // );
    };

    const renderConditions = (scope, score, bgcolor) => {
        return (
            // <div
            //     className="col-12 col-md-2"
            //     style={{ marginTop: '1em', marginBottom: '1em', paddingLeft: 0, paddingRight: 0 }}
            // >
            //     <div className="cond-1" style={{ backgroundColor: bgcolor, borderTopLeftRadius: 12, borderTopRightRadius: 12 }}>
            //         <img
            //             src={`/assets/images/thyroid/thyroid-report-pc-${scope}.svg`}
            //             alt={`thyroid-report-pc-${scope}`}
            //         />
            //         <h4
            //             style={{
            //                 fontSize: 16,
            //                 fontWeight: 500,
            //                 color: '#FFFFFF',
            //                 minHeight: 35,
            //                 textAlign: 'center',
            //             }}
            //         >
            //             {preConditions[scope][0]}
            //         </h4>
            //     </div>
            //     <div
            //         style={{
            //             backgroundColor: '#FFFFFF',
            //             border: '1px solid rgba(55, 55, 80, 0.2)',
            //             marginTop: 0,
            //             borderBottomLeftRadius: 12,
            //             borderBottomRightRadius: 12,
            //             padding: 20,
            //         }}
            //     >
            //         <center>
            //             <div style={{ height: 48, width: 48 }}>
            //                 <CircularProgressbar
            //                     styles={buildStyles({
            //                         textColor: getTextColor(score),
            //                         pathColor: getTextColor(score),
            //                         trailColor: '#AAAAAA',
            //                     })}
            //                     value={score}
            //                     text={`${score}%`}
            //                 />
            //             </div>
            //             <div>
            //                 <h4 style={{ fontSize: 16, fontWeight: 400, color: '#10202D', fontFamily: 'Merriweather Sans' }}>{getSeverity(score)}</h4>
            //             </div>
            //         </center>
            //     </div>
            // </div>
            <div className="thyroid-glance-container">
                <div className="thyroid-glance-container-p1" style={{ backgroundColor: bgcolor }}>
                    <img
                        src={`/assets/images/thyroid/thyroid-report-pc-${scope}.svg`}
                        alt={`thyroid-report-pc-${scope}`}
                    />
                    <h4
                        style={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: '#FFFFFF',
                            minHeight: 35,
                            textAlign: 'center',
                            maxWidth: "186px"
                        }}
                    >
                        {preConditions[scope][0]}
                    </h4>
                </div>
                <div className="thyroid-glance-container-p2">
                    <div
                        style={{
                            backgroundColor: '#FFFFFF',
                            border: '1px solid rgba(55, 55, 80, 0.2)',
                            marginTop: 0,
                            borderBottomLeftRadius: 12,
                            borderBottomRightRadius: 12,
                            padding: 20,
                        }}
                    >
                        <div className='thyroid-glance-progress-bar-container'>
                            <div style={{ height: 48, width: 48 }}>
                                <CircularProgressbar
                                    styles={buildStyles({
                                        textColor: getTextColor(score),
                                        pathColor: getTextColor(score),
                                        trailColor: '#AAAAAA',
                                    })}
                                    value={score}
                                    text={`${score}%`}
                                />
                            </div>
                            <div>
                                <h4 style={{ fontSize: 16, fontWeight: 400, color: '#10202D', fontFamily: 'Merriweather Sans' }}>{getSeverity(score)}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    };

    return (
        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
            <div>
                <div className="row" style={{ marginTop: '2em', gap: '33px', justifyContent: 'center' }}>
                    <div
                        className="col-12 col-md-4"
                        style={{ backgroundColor: ' #275070', borderRadius: 12, borderBottomLeftRadius: 12, maxHeight: '372px', maxWidth: '326px' }}
                    >
                        <center>
                            <h4

                                style={{
                                    fontFamily: 'Montserrat',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: '20px',
                                    lineHeight: '28px',

                                    letterSpacing: '0.25px',

                                    /* lace */

                                    color: '#FFF5F5',

                                    marginTop: '2em',
                                    marginBottom: '2em',
                                }}
                            >

                                Your Thyroid Severity is
                            </h4>

                            <div style={{ height: 140, width: 140 }}>
                                <CircularProgressbar
                                    styles={buildStyles({
                                        textColor: '#FFFFFF',
                                        pathColor: '#FFFFFF',
                                        trailColor: '#AAAAAA',
                                    })}
                                    value={finalScore}
                                    text={`${finalScore.toFixed(0)}%`}
                                />
                            </div>

                            <div
                                style={{
                                    backgroundColor: '#FFFFFF',
                                    borderRadius: 11,
                                    padding: 17,
                                    marginTop: '2em',
                                    marginBottom: '1em',
                                }}
                            >
                                <div style={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: '#373750',
                                    fontWeight: 300,
                                    fontSize: '12px',
                                    lineHeight: '18px',
                                    /* identical to box height, or 150% */
                                    letterSpacing: '0.01em',
                                    color: '#10202D',
                                    marginLeft: '14px',
                                    marginRight: '14px',

                                }}>
                                    <i className="fa-solid fa-circle-exclamation" style={{ color: 'orange' }}></i>
                                    &nbsp; Your Thyroid Health needs attention
                                </div>
                            </div>
                        </center>
                    </div>
                    {!isMobile && (<div
                        className="col-12 col-md-8"
                        style={{
                            backgroundColor: 'white',

                            border: '1px solid rgba(0, 0, 0, 0.1)',
                            borderRadius: 12,
                            padding: 20,
                            maxHeight: '372px',
                        }}
                    >
                        <div className="row justify-content-center align-items-center">
                            <div className="col-3">
                                <h3 style={{ fontSize: 16, fontWeight: 400, color: '#DC4A9A', fontFamily: 'Open Sans' }}>Name</h3>
                                <h4 style={{ fontSize: 18, fontWeight: 600, color: ' #10202D', fontFamily: 'Montserrat' }}>{name}</h4>
                            </div>

                            <div className="col-4">
                                {/* <h3 style={{ fontSize: 16, fontWeight: 400, color: '#DC4A9A', fontFamily: 'Open Sans' }}>Diagnosed</h3> */}
                                <h4 style={{ fontSize: 16, fontWeight: 300, color: ' #10202D', fontFamily: 'Merriweather Sans' }}>{diagnosed}</h4>
                            </div>

                            <div className="col-5" style={{}}>
                                <a
                                    className="btn btn-primary btn-block hc-button-3"
                                    href="https://uvihealth.in/health-coach?utm_source=thyroidquiz&utm_medium=website"
                                >
                                    Consult Health Coach &nbsp;
                                    <i className="fa-solid fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: '20px' }}>
                            <div className="col-4">
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'left',
                                    }}
                                >
                                    <div style={{ marginRight: 10 }}>
                                        <img src="/assets/images/thyroid/hero-age.svg" alt="hero-age" />
                                    </div>
                                    <div>
                                        <h3 style={{ fontSize: 16, fontWeight: 400, color: '#DC4A9A', fontFamily: 'Open Sans' }}>Age</h3>
                                        <h4
                                            style={{ fontSize: 18, fontWeight: 600, color: ' #10202D', fontFamily: 'Montserrat' }}
                                        >{`${age} years`}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-4">
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'left',
                                    }}
                                >
                                    <div style={{ marginRight: 10 }}>
                                        <img src="/assets/images/thyroid/hero-height.svg" alt="hero-height" />
                                    </div>
                                    <div>
                                        <h3 style={{ fontSize: 16, fontWeight: 400, color: '#DC4A9A', fontFamily: 'Open Sans' }}>Height</h3>
                                        <h4
                                            style={{ fontSize: 18, fontWeight: 600, color: ' #10202D', fontFamily: 'Montserrat' }}
                                        >{`${height} cms`}</h4>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'left',
                                    }}
                                >
                                    <div style={{ marginRight: 10 }}>
                                        <img src="/assets/images/thyroid/hero-weight.svg" alt="hero-weight" />
                                    </div>
                                    <div>
                                        <h3 style={{ fontSize: 16, fontWeight: 400, color: '#DC4A9A', fontFamily: 'Open Sans' }}>Weight</h3>
                                        <h4
                                            style={{ fontSize: 18, fontWeight: 600, color: ' #10202D', fontFamily: 'Montserrat' }}
                                        >{`${weight} kgs`}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: '20px', marginBottom: '1em', display: 'flex' }}>
                            <h3 style={{ fontSize: 20, fontWeight: 600, color: ' #10202D', fontFamily: 'Montserrat' }}>Symptoms</h3>
                        </div>

                        <div className="row">

                            {
                                mainSymptoms && mainSymptoms.includes('Weight Gain') ? 
                                <div className="col-3">
                                <center>
                                    <img
                                        src="/assets/images/thyroid/hypo-wight-gain.svg"
                                        alt="hypo-wight-gain"
                                    />
                                    <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D' }}>
                                    Weight Gain
                                    </h4>
                                </center>
                            </div> : null
                            }
                            {
                                mainSymptoms && mainSymptoms.includes('Sensitivity to cold') ? 
                                <div className="col-3">
                                <center>
                                    <img
                                        src="/assets/images/thyroid/hypo-cold-sen.svg"
                                        alt="thypo-cold-sen"
                                    />
                                    <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D' }}>
                                    Sensitivity to cold
                                    </h4>
                                </center>
                            </div> : null
                            }
                            {
                                mainSymptoms && mainSymptoms.includes('Dry Skin') ? 
                                <div className="col-3">
                                <center>
                                    <img
                                        src="/assets/images/thyroid/hypo-skin.svg"
                                        alt="hypo-skin"
                                    />
                                    <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D' }}>
                                    Dry Skin
                                    </h4>
                                </center>
                            </div> : null
                            }
                            {
                                mainSymptoms && mainSymptoms.includes('Hair Thinning') ? 
                                <div className="col-3">
                                <center>
                                    <img
                                        src="/assets/images/thyroid/hypo-hair.svg"
                                        alt="hypo-hair"
                                    />
                                    <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D' }}>
                                    Hair Thinning
                                    </h4>
                                </center>
                            </div> : null
                            }
                            {
                                mainSymptoms && mainSymptoms.includes('Irregular periods') ? 
                                <div className="col-3">
                                <center>
                                    <img
                                        src="/assets/images/thyroid/period.svg"
                                        alt="period"
                                    />
                                    <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D' }}>
                                    Irregular periods
                                    </h4>
                                </center>
                            </div> : null
                            }
                            {
                                mainSymptoms && mainSymptoms.includes('Weight Loss') ? 
                                <div className="col-3">
                                <center>
                                    <img
                                        src="/assets/images/thyroid/hyper-wight-loss.svg"
                                        alt="hyper-wight-loss"
                                    />
                                    <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D' }}>
                                    Weight Loss
                                    </h4>
                                </center>
                            </div> : null
                            }
                            {
                                mainSymptoms && mainSymptoms.includes('Tiredness/Body ache') ? 
                                <div className="col-3">
                                <center>
                                    <img
                                        src="/assets/images/thyroid/body-ache.svg"
                                        alt="body-ache"
                                    />
                                    <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D' }}>
                                    Tiredness/Body ache
                                    </h4>
                                </center>
                            </div> : null
                            }
                            {
                                mainSymptoms && mainSymptoms.includes('Sensitivity to heat') ? 
                                <div className="col-3">
                                <center>
                                    <img
                                        src="/assets/images/thyroid/hyper-warn-sen.svg"
                                        alt="hyper-warn-sen"
                                    />
                                    <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D' }}>
                                    Sensitivity to heat
                                    </h4>
                                </center>
                            </div> : null
                            }
                            {
                                mainSymptoms && mainSymptoms.includes('Warm/Moist Skin') ? 
                                <div className="col-3">
                                <center>
                                    <img
                                        src="/assets/images/thyroid/hyper-skin.svg"
                                        alt="hyper-skin"
                                    />
                                    <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D' }}>
                                    Warm/Moist Skin
                                    </h4>
                                </center>
                            </div> : null
                            }
                            {
                                mainSymptoms && mainSymptoms.includes('Hair Breakage/Loss') ? 
                                <div className="col-3">
                                <center>
                                    <img
                                        src="/assets/images/thyroid/hyper-hair.svg"
                                        alt="hyper-hair"
                                    />
                                    <h4 style={{ fontSize: 14, fontWeight: 300, color: '#10202D' }}>
                                    Hair Breakage/Loss
                                    </h4>
                                </center>
                            </div> : null
                            }
                        </div>
                    </div>)}
                </div>
            </div >

            <div>
                <div className="row justify-content-center" style={{ marginTop: '6em', marginBottom: '3em' }}>
                    <div className='pre-condition' >
                        Your Thyroid Pre-Conditions at a Glance
                    </div>
                </div>

                <div className="row" style={{ justifyContent: 'center', gap: isMobile ? "10px" : "25px", alignItems: 'center' }}>
                    {renderConditions('ci', ciScore, '#668CA1')}
                    {renderConditions('gd', gdScore, '#9073A2')}
                    {renderConditions('ms', msScore, '#B88167')}
                    {renderConditions('nd', ndScore, '#8E6182')}
                    {renderConditions('ge', geScore, '#6271B3')}
                </div>
            </div>

            <div style={{ marginTop: '3em' }}>
                <div className="row align-items-center">
                    <div className="col-12 col-md-7">
                        <h4 className='factors'>
                            95%+ Hypothyroidism is caused by one of the 3 underlying factors
                        </h4>

                        <div style={{ marginTop: '2em', marginBottom: '2em' }}>
                            <h5 className='pointers'>
                                1. Hashimoto's
                            </h5>
                            <h5 className='pointers'>
                                2. HPA Axis Dysfunction
                            </h5>
                            <h5 className='pointers'>
                                3. Estrogen Excess
                            </h5>
                        </div>

                        <div style={{ marginTop: '2em', marginBottom: '2em' }}>
                            <h6 className='factors-subhead'>
                                These root causes are treatable. Book a thyroid root cause panel to find your clinical
                                root cause and begin personalised treatment.
                            </h6>
                        </div>

                        <div style={{ marginTop: '2em', marginBottom: '2em' }}>
                            <div className="factor-btns">
                                <div >
                                    <a
                                        href="https://uvihealth.in/health-coach?utm_source=thyroidquiz&utm_medium=website"
                                        className="btn btn-primary btn-block hc-button-2"
                                        style={{ backgroundColor: "#275070", border: "#275070" }}
                                    >
                                        Consult Health Coach
                                    </a>
                                </div>

                                {/* <div className="col-1"></div> */}

                                <div>
                                    <a
                                        href="https://uvihealth.in/lab-tests/slot/thyroid-root-cause-panel"
                                        className="btn btn-primary btn-block root-cause-panel-button"
                                        style={{ backgroundColor: "#DC4A9A", border: "#DC4A9A" }}
                                    >
                                        Book Root Cause Panel
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-1"></div>

                    <div className="col-12 col-md-4">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                src="/assets/images/thyroid/thyroid-reason.png"
                                style={{ height: '100%', width: '100%' }}
                                alt="thyroid-reason"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ marginTop: '3em', marginBottom: '3em' }}>
                <div className="row justify-content-center">
                    <h3
                        className='precondition'
                    >
                        Your Thyroid Pre-Conditions Explained
                    </h3>
                </div>

                {renderPreConditions('ci', ciScore)}
                {renderPoints('ci')}

                {renderPreConditions('gd', gdScore)}
                {renderPoints('gd')}

                {renderPreConditions('ms', msScore)}
                {renderPoints('ms')}

                {renderPreConditions('nd', ndScore)}
                {renderPoints('nd')}

                {renderPreConditions('ge', geScore)}
                {renderPoints('ge')}
            </div>


            <div style={{ marginTop: '8em', marginBottom: '3em' }}>
                <div className='help-container'>
                    <div className='help-head'>
                        Have any questions? Book a call with us
                    </div>
                    <div className='btn-container'>
                        <a
                            className="btn btn-primary btn-lg hc-button-1"
                            href="https://uvihealth.in/health-coach?utm_source=thyroidquiz&utm_medium=website"
                        >
                            Talk to Health Coach
                        </a>
                    </div>
                </div>
                {/* <div className="row justify-content-center">
                    <h3 style={{ fontSize: 28, fontWeight: 600, color: ' #10202D', fontFamily: 'Montserrat', lineHeight: '1.5em' }}>
                        Have any questions? Book a call with us
                    </h3>
                </div>
                <div className="row justify-content-center" style={{ marginTop: '2em' }}>
                    <a
                        className="btn btn-primary btn-lg hc-button-1"
                        href="https://uvihealth.in/health-coach?utm_source=thyroidquiz&utm_medium=website"
                    >
                        Talk to Health Coach
                    </a>
                </div> */}
            </div>
            <div style={{ marginTop: '8em', marginBottom: '3em' }}>
                <div className='playstore-conatainer'>
                    <div className='playstore-content-1'>
                        <div className='playstore-head'>
                            We make it easy for you to
                            prioritise your health
                        </div >
                        <div className='playstore-subhead'>
                            Experience live & interactive fitness sessions, nutritionist & doctor consults & get easy access to at-home diagnostics
                        </div>
                        <div className='playstore-data'>
                            <div className='playstore-datas'>

                                <div className='playstore-datas-p1'>
                                    4.8
                                </div>
                                <div className='playstore-datas-p2'>
                                    Rated on Play Store
                                </div>
                            </div>
                            <div className='playstore-datas'>
                                <div className='playstore-datas-p1'>
                                    90%+
                                </div>
                                <div className='playstore-datas-p2'>
                                    Positive Results
                                </div>
                            </div>
                            <div className='playstore-datas'>
                                <div className='playstore-datas-p1'>
                                    50k+
                                </div>
                                <div className='playstore-datas-p2'>
                                    Happy Users
                                </div>
                            </div>
                        </div>
                        <div className='store-btns'>
                            <a href='https://play.google.com/store/apps/details?id=com.uvihealth.app&hl=en_IN&gl=US'>
                                <img className='store-btns-1' src='/assets/images/google-play-btn.png' alt='playstore' />
                            </a>
                            <a href='https://apps.apple.com/in/app/uvi-health/id1521502013'>
                                <img className='store-btns-2' src='/assets/images/appstore-btn.png' alt='appstore' />
                            </a>
                        </div>
                    </div>
                    <div className='playstore-content-2'>
                        <img className='phone-img' src='/assets/images/phone-1.png' alt='phone-1' />
                        <img className='phone-img' src='/assets/images/phone-2.png' alt='phone-2' />
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-12 col-md-7"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >

                    </div>

                    {/* <div className="col-12 col-md-5">
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                src="/assets/images/thyroid/thyroid-report-app-2.png"
                                style={{ height: 300, marginRight: 10 }}
                            />
                            <img
                                src="/assets/images/thyroid/thyroid-report-app-1.png"
                                style={{ height: 300, marginLeft: 10 }}
                            />
                        </div>
                    </div> */}
                </div>
            </div>


        </div >
    );
};

export default ThyroidReport;
