import React from "react";

const QuizAge = ({ handleChange }) => {
    const handleClick = () => {
        handleChange("hero", null);
    };

    return (
        <div className="uvi-landing-main extra-padding"  >
            <h2 className="landing-home-hero-1">Ready to begin?</h2>
            <h4 className="landing-home-hero-2">
                Let's start by analysing the root cause of your PCOS.
            </h4>
            <h4 className="quiz-home-hero-1">
                Completing this quiz may take around
                2 to 3 minutes of your time.
                Once you have finished taking the quiz, you will be immediately
                be able to unlock your results.
            </h4>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2em",
                }}
            >
                <img
                    src="/assets/images/uvi-line.svg"
                    className="hero-img"
                    alt="uvi-hero"
                />
            </div>
            <button
                className="btn btn-primary quiz-forward btn-block"
                onClick={handleClick}
            >
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <div>
                        Start Quiz
                    </div>
                    <div>
                        <img src="/assets/images/arrow-right.svg" className="arrow-right" alt="arrow-right" />
                    </div>
                </div>
            </button>
        </div >
    );
};

export default QuizAge;
