const data = [
    {
        id: 'q1',
        title: 'First, tell us a little about yourself',
        subtitle: 'What is your name?',
        forward: 'q2',
        backward: null,
        type: 'input-box',
        data: [
            {
                type: 'textfield',
                target: 'name',
                placeholder: 'Enter your name',
            },
        ],
    },
    {
        id: 'q2',
        title: 'Hi, how old are you?',
        subtitle: '',
        forward: 'q3',
        backward: 'q1',
        type: 'input-box',
        data: [
            {
                type: 'textfield',
                target: 'age',
                placeholder: 'Enter your age',
            },
        ],
    },
    {
        id: 'q3',
        title: 'Please tell us your current height',
        subtitle: '',
        forward: 'q3.5',
        backward: 'q2',
        type: 'input-box',
        data: [
            {
                type: 'textfield',
                target: 'height',
                placeholder: 'Enter your height in cms',
            },
        ],
    },
    {
        id: 'q3.5',
        title: 'Please tell us your current weight',
        subtitle: '',
        forward: 'q4',
        backward: 'q3',
        type: 'input-box',
        data: [
            {
                type: 'textfield',
                target: 'weight',
                placeholder: 'Enter your weight in kgs',
            },
        ],
    },
    {
        id: 'q4',
        title: 'Have you been diagnosed with any Thyroid disorder?',
        subtitle: '',
        forward: null,
        backward: 'q3',
        type: 'single-choice',
        data: [
            {
                type: 'single-choice-item',
                target: 'thyroid-diagnosis',
                value: 'Yes',
                forward: 'q5',
            },
            {
                type: 'single-choice-item',
                target: 'thyroid-diagnosis',
                value: 'No',
                forward: 'q7',
            },
        ],
    },
    {
        id: 'q5',
        title: 'When were you diagnosed?',
        subtitle: '',
        forward: null,
        backward: 'q4',
        type: 'single-choice',
        data: [
            {
                type: 'single-choice-item',
                target: 'diagnosed-time',
                value: 'Less than 6 months ago',
                forward: 'q6',
            },
            {
                type: 'single-choice-item',
                target: 'diagnosed-time',
                value: '6 months - 1 year',
                forward: 'q6',
            },
            {
                type: 'single-choice-item',
                target: 'diagnosed-time',
                value: '1 - 3 years ago',
                forward: 'q6',
            },
            {
                type: 'single-choice-item',
                target: 'diagnosed-time',
                value: '3+ years ago',
                forward: 'q6',
            },
        ],
    },
    {
        id: 'q6',
        title: 'What type of Thyroid disorder do you have?',
        subtitle: '',
        forward: null,
        backward: 'q5',
        type: 'single-choice',
        data: [
            {
                type: 'single-choice-item',
                target: 'diagnosis-status',
                value: 'Hypothyroidism',
                forward: 'qminhypo',
            },
            {
                type: 'single-choice-item',
                target: 'diagnosis-status',
                value: 'Hyperthyroidism',
                forward: 'qminhyper',
            },
            {
                type: 'single-choice-item',
                target: 'diagnosis-status',
                value: 'Not Sure',
                forward: 'q7',
            },
        ],
    },
    {
        id: 'q7',
        title: 'Which symptoms do you relate with more?',
        subtitle: '',
        forward: null,
        backward: 'q6',
        type: 'single-choice',
        data: [
            {
                type: 'single-choice-item',
                target: 'diagnosis-status',
                value: 'Hypothyroidism: unexplained weight gain, cold intolerance, heavy menstrual periods',
                forward: 'qminhypo',
            },
            {
                type: 'single-choice-item',
                target: 'diagnosis-status',
                value: 'Hyperthyroidism: unexplained weight loss, heat intolerance, scant menstrual periods',
                forward: 'qminhyper',
            },
        ],
    },
    {
        //Symptoms for Hyper
        id: 'qminhyper',
        title: 'Which of following apply to you?',
        subtitle: '',
        forward: null,
        backward: 'q6',
        type: 'multi-choice',
        data: [
            {
                type: 'multi-choice-item',
                target: 'main-symptoms',
                value: 'Weight Loss',
                forward: 'q26',
            },
            {
                type: 'multi-choice-item',
                target: 'main-symptoms',
                value: 'Tiredness/Body ache',
                forward: 'q26',
            },
            {
                type: 'multi-choice-item',
                target: 'main-symptoms',
                value: 'Sensitivity to heat',
                forward: 'q26',
            },
            {
                type: 'multi-choice-item',
                target: 'main-symptoms',
                value: 'Warm/Moist Skin',
                forward: 'q26',
            },
            {
                type: 'multi-choice-item',
                target: 'main-symptoms',
                value: 'Hair Breakage/Loss',
                forward: 'q26',
            },
            {
                type: 'multi-choice-item',
                target: 'main-symptoms',
                value: 'Irregular periods',
                forward: 'q26',
            },
        ],
    },
    {
        //Symptoms for Hypo
        id: 'qminhypo',
        title: 'Which of following apply to you?',
        subtitle: '',
        forward: null,
        backward: 'q6',
        type: 'multi-choice',
        data: [
            {
                type: 'multi-choice-item',
                target: 'main-symptoms',
                value: 'Weight Gain',
                forward: 'q8',
            },
            {
                type: 'multi-choice-item',
                target: 'main-symptoms',
                value: 'Tiredness/Body ache',
                forward: 'q8',
            },
            {
                type: 'multi-choice-item',
                target: 'main-symptoms',
                value: 'Sensitivity to cold',
                forward: 'q8',
            },
            {
                type: 'multi-choice-item',
                target: 'main-symptoms',
                value: 'Dry Skin',
                forward: 'q8',
            },
            {
                type: 'multi-choice-item',
                target: 'main-symptoms',
                value: 'Hair Thinning',
                forward: 'q8',
            },
            {
                type: 'multi-choice-item',
                target: 'main-symptoms',
                value: 'Irregular periods',
                forward: 'q8',
            },
        ],
    },
    {
        id: 'q8',
        title: 'Do you experience chronic pain in muscles or joints or muscular cramps?',
        subtitle: '',
        forward: null,
        backward: 'q7',
        type: 'single-choice',
        section: 'Chronic Inflammation',
        progress: 33,
        data: [
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-chronic-inflammation-1',
                value: 'Yes',
                score: 3,
                forward: 'q9',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-chronic-inflammation-1',
                value: 'No',
                score: 0,
                forward: 'q9',
            },
        ],
    },
    {
        id: 'q9',
        title: 'Do you often feel drowsy or tired even if you do not exert yourself?',
        subtitle: '',
        forward: null,
        backward: 'q8',
        type: 'single-choice',
        section: 'Chronic Inflammation',
        progress: 67,
        data: [
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-chronic-inflammation-2',
                value: 'Yes',
                score: 3,
                forward: 'q10',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-chronic-inflammation-2',
                value: 'No',
                score: 0,
                forward: 'q10',
            },
        ],
    },
    {
        id: 'q10',
        title: 'Does your thyroid gland (neck area) appears to be swollen?',
        subtitle: '',
        forward: null,
        backward: 'q9',
        type: 'single-choice',
        section: 'Chronic Inflammation',
        progress: 100,
        data: [
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-chronic-inflammation-3',
                value: 'Yes',
                score: 3,
                forward: 'q11',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-chronic-inflammation-3',
                value: 'No',
                score: 0,
                forward: 'q11',
            },
        ],
    },
    {
        id: 'q11',
        title: 'Do you experience constipation / chronic abdominal pain / loss of appetite?',
        subtitle: '',
        forward: null,
        backward: 'q10',
        type: 'single-choice',
        section: 'Gut Health',
        progress: 33,
        data: [
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-gut-health-1',
                value: 'Yes',
                score: 3,
                forward: 'q12',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-gut-health-1',
                value: 'No',
                score: 0,
                forward: 'q12',
            },
        ],
    },
    {
        id: 'q12',
        title: 'Which of the following foods do you consume in your regular diet quite often?',
        subtitle: '',
        forward: null,
        backward: 'q11',
        type: 'multi-choice',
        section: 'Gut Health',
        progress: 67,
        data: [
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-gut-health-2',
                value: 'Soy Products',
                score: 1,
                forward: 'q13',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-gut-health-2',
                value: 'Red meat, pork, processed meat',
                score: 1,
                forward: 'q13',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-gut-health-2',
                value: 'Dairy Products',
                score: 1,
                forward: 'q13',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-gut-health-2',
                value: 'Gluten Products',
                score: 1,
                forward: 'q13',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-gut-health-2',
                value: 'High Iodine Products',
                score: 1,
                forward: 'q13',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-gut-health-2',
                value: 'Uncooked cruciferous vegetables like cabbage and broccoli',
                score: 1,
                forward: 'q13',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-gut-health-2',
                value: 'None of the above',
                score: 0,
                forward: 'q13',
            },
        ],
    },
    {
        id: 'q13',
        title: 'Do you have gluten or dairy intolerance? For instance, do you feel bloated after consuming gluten or dairy products?',
        subtitle: '',
        forward: null,
        backward: 'q12',
        type: 'single-choice',
        section: 'Gut Health',
        progress: 100,
        data: [
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-gut-health-3',
                value: 'Yes',
                score: 1,
                forward: 'q14',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-gut-health-3',
                value: 'No',
                score: 0,
                forward: 'q14',
            },
        ],
    },
    {
        id: 'q14',
        title: 'Which of the following do you experience?',
        subtitle: '',
        forward: null,
        backward: 'q13',
        type: 'multi-choice',
        section: 'Metabolic Stress',
        progress: 25,
        data: [
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-metabolic-stress-1',
                value: 'Extreme sleepiness during the day',
                score: 2,
                forward: 'q15',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-metabolic-stress-1',
                value: 'Feelings of being depressed or experiencing brain fog',
                score: 2,
                forward: 'q15',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-metabolic-stress-1',
                value: 'Hair Loss / Coarse hair or thinning of eyebrows',
                score: 2,
                forward: 'q15',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-metabolic-stress-1',
                value: 'None of the above',
                score: 0,
                forward: 'q15',
            },
        ],
    },
    {
        id: 'q15',
        title: 'Which of the following do you experience?',
        subtitle: '',
        forward: null,
        backward: 'q14',
        type: 'multi-choice',
        section: 'Metabolic Stress',
        progress: 50,
        data: [
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-metabolic-stress-2',
                value: 'Changes in your menstrual cycles like irregular periods or fertility issues or mood swings?',
                score: 2,
                forward: 'q16',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-metabolic-stress-2',
                value: 'Heavy Menstrual Periods',
                score: 2,
                forward: 'q16',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-metabolic-stress-2',
                value: 'Puffy Face',
                score: 2,
                forward: 'q16',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-metabolic-stress-2',
                value: 'None of the above',
                score: 0,
                forward: 'q16',
            },
        ],
    },
    {
        id: 'q16',
        title: 'Which of the following do you experience?',
        subtitle: '',
        forward: null,
        backward: 'q15',
        type: 'multi-choice',
        section: 'Metabolic Stress',
        progress: 75,
        data: [
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-metabolic-stress-3',
                value: 'Increased sensitivity to cold / particularly cold hand and feat',
                score: 3,
                forward: 'q17',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-metabolic-stress-3',
                value: 'Slowed Heartbeat quite often during a week or for a prolonged time',
                score: 3,
                forward: 'q17',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-metabolic-stress-3',
                value: 'None of the above',
                score: 0,
                forward: 'q17',
            },
        ],
    },
    {
        id: 'q17',
        title: 'Have you experienced unexplained weight gain?',
        subtitle: '',
        forward: null,
        backward: 'q16',
        type: 'single-choice',
        section: 'Metabolic Stress',
        progress: 100,
        data: [
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-metabolic-stress-4',
                value: 'Yes',
                score: 3,
                forward: 'q18',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-metabolic-stress-4',
                value: 'No',
                score: 0,
                forward: 'q18',
            },
        ],
    },
    {
        id: 'q18',
        title: 'Have you been diagnosed with deficiencies of any of the following vitamins/supplements? If yes, please indicate which ones.',
        subtitle: '',
        forward: null,
        backward: 'q17',
        type: 'multi-choice',
        section: 'Nutritional Deficiencies',
        progress: 25,
        data: [
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-nutritional-deficiencies-1',
                value: 'Vitamin D',
                score: 1,
                forward: 'q19',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-nutritional-deficiencies-1',
                value: 'Vitamin B12',
                score: 1,
                forward: 'q19',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-nutritional-deficiencies-1',
                value: 'Magnesium',
                score: 1,
                forward: 'q19',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-nutritional-deficiencies-1',
                value: 'Zinc',
                score: 1,
                forward: 'q19',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-nutritional-deficiencies-1',
                value: 'Vitamin A',
                score: 1,
                forward: 'q19',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-nutritional-deficiencies-1',
                value: 'Vitamin B2',
                score: 1,
                forward: 'q19',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-nutritional-deficiencies-1',
                value: 'Vitamin B6',
                score: 1,
                forward: 'q19',
            },
            {
                type: 'multi-choice-item',
                target: 'hypothyroidism-nutritional-deficiencies-1',
                value: 'Not Sure',
                score: 0,
                forward: 'q19',
            },
        ],
    },
    {
        id: 'q19',
        title: 'Do you experience numbness in your extremities or have carpel tunnel syndrome?',
        subtitle: '',
        forward: null,
        backward: 'q18',
        type: 'single-choice',
        section: 'Nutritional Deficiencies',
        progress: 50,
        data: [
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-nutritional-deficiencies-2',
                value: 'Yes',
                score: 3,
                forward: 'q20',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-nutritional-deficiencies-2',
                value: 'No',
                score: 0,
                forward: 'q20',
            },
        ],
    },
    {
        id: 'q20',
        title: 'Do you have dry skin or rough patches of skin on elbows?',
        subtitle: '',
        forward: null,
        backward: 'q19',
        type: 'single-choice',
        section: 'Nutritional Deficiencies',
        progress: 75,
        data: [
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-nutritional-deficiencies-3',
                value: 'Yes',
                score: 2,
                forward: 'q21',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-nutritional-deficiencies-3',
                value: 'No',
                score: 0,
                forward: 'q21',
            },
        ],
    },
    {
        id: 'q21',
        title: 'Do you have brittle nails?',
        subtitle: '',
        forward: null,
        backward: 'q20',
        type: 'single-choice',
        section: 'Nutritional Deficiencies',
        progress: 100,
        data: [
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-nutritional-deficiencies-4',
                value: 'Yes',
                score: 2,
                forward: 'q22',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-nutritional-deficiencies-4',
                value: 'No',
                score: 0,
                forward: 'q22',
            },
        ],
    },
    {
        id: 'q22',
        title: 'Do you have a family history of any thyroid disorder?',
        subtitle: '',
        forward: null,
        backward: 'q21',
        type: 'single-choice',
        section: 'Genetics and Environment',
        progress: 25,
        data: [
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-genetics-1',
                value: 'Yes',
                score: 2,
                forward: 'q23',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-genetics-1',
                value: 'No',
                score: 0,
                forward: 'q23',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-genetics-1',
                value: 'Not Sure',
                score: 1,
                forward: 'q23',
            },
        ],
    },
    {
        id: 'q23',
        title: 'Do you have a family history of auto-immune disorder?',
        subtitle: '',
        forward: null,
        backward: 'q22',
        type: 'single-choice',
        section: 'Genetics and Environment',
        progress: 50,
        data: [
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-genetics-2',
                value: 'Yes',
                score: 2,
                forward: 'q24',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-genetics-2',
                value: 'No',
                score: 0,
                forward: 'q24',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-genetics-2',
                value: 'Not Sure',
                score: 1,
                forward: 'q24',
            },
        ],
    },
    {
        id: 'q24',
        title: 'Do you have a family history of metabolic disorder like diabetes?',
        subtitle: '',
        forward: null,
        backward: 'q23',
        type: 'single-choice',
        section: 'Genetics and Environment',
        progress: 75,
        data: [
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-genetics-3',
                value: 'Yes',
                score: 1,
                forward: 'q25',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-genetics-3',
                value: 'No',
                score: 0,
                forward: 'q25',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-genetics-3',
                value: 'Not Sure',
                score: 0.5,
                forward: 'q25',
            },
        ],
    },
    {
        id: 'q25',
        title: 'Have you been diagnosed with any toxicity in your body?',
        subtitle: '',
        forward: null,
        backward: 'q24',
        type: 'single-choice',
        section: 'Genetics and Environment',
        progress: 100,
        data: [
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-genetics-4',
                value: 'Yes',
                score: 1,
                forward: 'q43',
            },
            {
                type: 'single-choice-item',
                target: 'hypothyroidism-genetics-4',
                value: 'No',
                score: 0,
                forward: 'q43',
            },
        ],
    },
    {
        id: 'q26',
        title: 'Do you experience chronic pain in muscles or joints or muscular cramps?',
        subtitle: '',
        forward: null,
        backward: 'q6',
        type: 'single-choice',
        section: 'Chronic Inflammation',
        progress: 25,
        data: [
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-chronic-inflammation-1',
                value: 'Yes',
                score: 3,
                forward: 'q27',
            },
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-chronic-inflammation-1',
                value: 'No',
                score: 0,
                forward: 'q27',
            },
        ],
    },
    {
        id: 'q27',
        title: 'Do you often feel drowsy or tired even if you do not exert yourself?',
        subtitle: '',
        forward: null,
        backward: 'q26',
        type: 'single-choice',
        section: 'Chronic Inflammation',
        progress: 50,
        data: [
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-chronic-inflammation-2',
                value: 'Yes',
                score: 3,
                forward: 'q28',
            },
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-chronic-inflammation-2',
                value: 'No',
                score: 0,
                forward: 'q28',
            },
        ],
    },
    {
        id: 'q28',
        title: 'Does your thyroid gland (neck area) appears to be swollen?',
        subtitle: '',
        forward: null,
        backward: 'q27',
        type: 'single-choice',
        section: 'Chronic Inflammation',
        progress: 75,
        data: [
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-chronic-inflammation-3',
                value: 'Yes',
                score: 3,
                forward: 'q29',
            },
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-chronic-inflammation-3',
                value: 'No',
                score: 0,
                forward: 'q29',
            },
        ],
    },
    {
        id: 'q29',
        title: 'Do you think your eyes are bulging?',
        subtitle: '',
        forward: null,
        backward: 'q28',
        type: 'single-choice',
        section: 'Chronic Inflammation',
        progress: 100,
        data: [
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-chronic-inflammation-4',
                value: 'Yes',
                score: 3,
                forward: 'q30',
            },
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-chronic-inflammation-4',
                value: 'No',
                score: 0,
                forward: 'q30',
            },
        ],
    },
    {
        id: 'q30',
        title: 'Do you experience frequent bowel movements / nausea / increase in appetite?',
        subtitle: '',
        forward: null,
        backward: 'q29',
        type: 'single-choice',
        section: 'Gut Health',
        progress: 33,
        data: [
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-gut-health-1',
                value: 'Yes',
                score: 3,
                forward: 'q31',
            },
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-gut-health-1',
                value: 'No',
                score: 0,
                forward: 'q31',
            },
        ],
    },
    {
        id: 'q31',
        title: 'Which of the following foods do you consume in your regular diet quite often?',
        subtitle: '',
        forward: null,
        backward: 'q30',
        type: 'multi-choice',
        section: 'Gut Health',
        progress: 67,
        data: [
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-gut-health-2',
                value: 'Soy Products',
                score: 1,
                forward: 'q32',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-gut-health-2',
                value: 'Dairy Products',
                score: 1,
                forward: 'q32',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-gut-health-2',
                value: 'Gluten Products',
                score: 1,
                forward: 'q32',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-gut-health-2',
                value: 'High Iodine Products',
                score: 1,
                forward: 'q32',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-gut-health-2',
                value: 'Seafood like fish, prawns, etc',
                score: 1,
                forward: 'q32',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-gut-health-2',
                value: 'None of the above',
                score: 0,
                forward: 'q32',
            },
        ],
    },
    {
        id: 'q32',
        title: 'Do you have gluten or dairy intolerance? For instance, do you feel bloated after consuming gluten or dairy products?',
        subtitle: '',
        forward: null,
        backward: 'q31',
        type: 'single-choice',
        section: 'Gut Health',
        progress: 100,
        data: [
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-gut-health-3',
                value: 'Yes',
                score: 1,
                forward: 'q33',
            },
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-gut-health-3',
                value: 'No',
                score: 0,
                forward: 'q33',
            },
        ],
    },
    {
        id: 'q33',
        title: 'Which of the following do you experience?',
        subtitle: '',
        forward: null,
        backward: 'q32',
        type: 'multi-choice',
        section: 'Metabolic Stress',
        progress: 25,
        data: [
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-metabolic-stress-1',
                value: 'Insomnia or heavy head in the morning',
                score: 2,
                forward: 'q34',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-metabolic-stress-1',
                value: 'Feelings of being anxious or irritable',
                score: 2,
                forward: 'q34',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-metabolic-stress-1',
                value: 'Hair Loss / Coarse hair or thinning of eyebrows',
                score: 2,
                forward: 'q34',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-metabolic-stress-1',
                value: 'Tremor of the hands or fingers',
                score: 2,
                forward: 'q34',
            },
        ],
    },
    {
        id: 'q34',
        title: 'Which of the following do you experience?',
        subtitle: '',
        forward: null,
        backward: 'q33',
        type: 'multi-choice',
        section: 'Metabolic Stress',
        progress: 50,
        data: [
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-metabolic-stress-2',
                value: 'Changes in your menstrual cycles like irregular periods or fertility issues or mood swings',
                score: 2,
                forward: 'q35',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-metabolic-stress-2',
                value: 'Scant menstrual periods',
                score: 2,
                forward: 'q35',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-metabolic-stress-2',
                value: 'Excessive sweating',
                score: 2,
                forward: 'q35',
            },
        ],
    },
    {
        id: 'q35',
        title: 'Which of the following do you experience?',
        subtitle: '',
        forward: null,
        backward: 'q34',
        type: 'multi-choice',
        section: 'Metabolic Stress',
        progress: 75,
        data: [
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-metabolic-stress-3',
                value: 'Increased sensitivity to heat / Warm or Moist Skin',
                score: 3,
                forward: 'q36',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-metabolic-stress-3',
                value: 'Rapid Heartbeat quite often during a week or for a prolonged time',
                score: 3,
                forward: 'q36',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-metabolic-stress-3',
                value: 'None of the above',
                score: 0,
                forward: 'q36',
            },
        ],
    },
    {
        id: 'q36',
        title: 'Have you experienced unexplained weight loss?',
        subtitle: '',
        forward: null,
        backward: 'q35',
        type: 'single-choice',
        section: 'Metabolic Stress',
        progress: 100,
        data: [
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-metabolic-stress-4',
                value: 'Yes',
                score: 3,
                forward: 'q37',
            },
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-metabolic-stress-4',
                value: 'No',
                score: 0,
                forward: 'q37',
            },
        ],
    },
    {
        id: 'q37',
        title: 'Have you been diagnosed with deficiencies of any of the following vitamins/supplements?',
        subtitle: '',
        forward: null,
        backward: 'q36',
        type: 'multi-choice',
        section: 'Nutritional Deficiencies',
        progress: 50,
        data: [
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-nutritional-deficiencies-1',
                value: 'Vitamin D',
                score: 1,
                forward: 'q38',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-nutritional-deficiencies-1',
                value: 'Vitamin B6',
                score: 1,
                forward: 'q38',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-nutritional-deficiencies-1',
                value: 'Vitamin C',
                score: 1,
                forward: 'q38',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-nutritional-deficiencies-1',
                value: 'Vitamin E',
                score: 1,
                forward: 'q38',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-nutritional-deficiencies-1',
                value: 'Vitamin B1',
                score: 1,
                forward: 'q38',
            },
            {
                type: 'multi-choice-item',
                target: 'hyperthyroidism-nutritional-deficiencies-1',
                value: 'Not Sure',
                score: 0,
                forward: 'q38',
            },
        ],
    },
    {
        id: 'q38',
        title: 'Do you have soft nails?',
        subtitle: '',
        forward: null,
        backward: 'q37',
        type: 'single-choice',
        section: 'Nutritional Deficiencies',
        progress: 100,
        data: [
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-nutritional-deficiencies-2',
                value: 'Yes',
                score: 2,
                forward: 'q39',
            },
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-nutritional-deficiencies-2',
                value: 'No',
                score: 0,
                forward: 'q39',
            },
        ],
    },
    {
        id: 'q39',
        title: 'Do you have a family history of any thyroid disorder?',
        subtitle: '',
        forward: null,
        backward: 'q38',
        type: 'single-choice',
        section: 'Genetic and Environment',
        progress: 25,
        data: [
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-genetics-1',
                value: 'Yes',
                score: 2,
                forward: 'q40',
            },
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-genetics-1',
                value: 'No',
                score: 0,
                forward: 'q40',
            },
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-genetics-1',
                value: 'Not Sure',
                score: 1,
                forward: 'q40',
            },
        ],
    },
    {
        id: 'q40',
        title: 'Do you have a family history of auto-immune disorder?',
        subtitle: '',
        forward: null,
        backward: 'q39',
        type: 'single-choice',
        section: 'Genetic and Environment',
        progress: 50,
        data: [
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-genetics-2',
                value: 'Yes',
                score: 2,
                forward: 'q41',
            },
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-genetics-2',
                value: 'No',
                score: 0,
                forward: 'q41',
            },
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-genetics-2',
                value: 'Not Sure',
                score: 1,
                forward: 'q41',
            },
        ],
    },
    {
        id: 'q41',
        title: 'Do you have a family history of metabolic disorder like diabetes?',
        subtitle: '',
        forward: null,
        backward: 'q40',
        type: 'single-choice',
        section: 'Genetic and Environment',
        progress: 75,
        data: [
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-genetics-3',
                value: 'Yes',
                score: 1,
                forward: 'q42',
            },
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-genetics-3',
                value: 'No',
                score: 0,
                forward: 'q42',
            },
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-genetics-3',
                value: 'Not Sure',
                score: 0.5,
                forward: 'q42',
            },
        ],
    },
    {
        id: 'q42',
        title: 'Have you been diagnosed with any toxicity in your body?',
        subtitle: '',
        forward: null,
        backward: 'q41',
        type: 'single-choice',
        section: 'Genetic and Environment',
        progress: 100,
        data: [
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-genetics-4',
                value: 'Yes',
                score: 1,
                forward: 'q43',
            },
            {
                type: 'single-choice-item',
                target: 'hyperthyroidism-genetics-4',
                value: 'No',
                score: 0,
                forward: 'q43',
            },
        ],
    },
    {
        id: 'q43',
        title: 'Your report is ready 🎉',
        subtitle: 'Submit your WhatsApp number below to receive your personalised Risk Assessment report',
        forward: 'final',
        backward: 'q1',
        type: 'phone-number',
        data: [
            {
                type: 'textfield',
                target: 'mobileNumber',
                placeholder: 'Enter your WhatsApp number',
            },
        ],
    },
];

export default data;
