import React, { useState } from "react";

const MainSymptoms = ({ handleChange }) => {
    const [answers, setAnswers] = useState([]);

    const setAnswer = (answer) => {
        const currentAnswers = JSON.parse(JSON.stringify(answers));

        if (currentAnswers.includes(answer)) {
            const index = currentAnswers.indexOf(answer);
            currentAnswers.splice(index, 1);
        } else {
            currentAnswers.push(answer);
        }

        setAnswers(currentAnswers);
    };

    const handleNextClick = () => {
        handleChange("main-symptoms", answers);
    };
    const handleBackClick = () => {
        handleChange("main-symptoms-back");
    };

    const baseAnswers = [
        "Irregular Periods",
        "Weight Gain",
        "Acne",
        "Hair Loss",
        "Abnormal Hair Growth",
        "Mood Swings",
    ];

    const renderAnswers = () => {
        return baseAnswers.map((val) => {
            return (
                <div className="col-12" key={Math.random()}>
                    <div
                        className={
                            answers.includes(val)
                                ? "quiz-options-selected"
                                : "quiz-options"
                        }
                        onClick={() => setAnswer(val)}
                    >
                        <i className={
                            answers.includes(val) ? 'fa-solid fa-square-check' : 'fa-regular fa-square'
                        }>
                        </i>&nbsp;&nbsp;
                        {val}
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="uvi-landing-main extra-padding">
            <div className="progress progress-md">
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "28%", backgroundColor: "#DC4A9A" }}
                    aria-valuenow="28"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
            <h2 className="quiz-home-hero-title">
                What are your main symptoms?
            </h2>

            <div className="row answer-section">{renderAnswers()}</div>

            <div>
                <div className="row">
                    <div className="col-12">
                        <button
                            className="btn btn-primary quiz-next"
                            onClick={handleNextClick}
                        >
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                <div>
                                    Next
                                </div>
                                <div>
                                    <img src="/assets/images/arrow-right.svg" className="arrow-right" alt="arrow-right" />
                                </div>
                            </div>
                        </button>

                        <button
                            className="btn btn-primary quiz-back"

                            onClick={handleBackClick}
                        >

                            Back
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainSymptoms;
